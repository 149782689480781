/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

function CoverLayout({ color, header, title, description, image, top, children, logo, activePage }) {
  return (
    <PageLayout background="white">
      {/* <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-react",
          label: "free download",
          color: "dark",
        }}
      /> */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "75vh",
          margin: 0,
        }}
      >
        <Grid item xs={11} sm={8} md={9} xl={4}>
          <SoftBox mt={5}>
            <SoftBox pt={3} px={3}>
              {!header ? (
                <>
                  <a href="https://www.credicorp.ng/" target="_blank" alt="" rel="noreferrer" style={{textDecoration:"none"}}>
                    <img src={logo} width="180px" />
                  </a>
                  <SoftBox mt={5} mb={1}>
                    <SoftTypography variant="h3" fontWeight="bold" color={color} textGradient>
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <SoftBox
            height="100%"
            display={{ xs: "none", xl: "block" }}
            position="relative"
            // right={{ md: "-12rem", xl: "-16rem" }}
            right={{ md: "-12rem", xl: "-8rem" }}
            mr={-16}
            sx={{
              // transform: "skewX(-10deg)",
              overflow: "hidden",
              borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
            }}
          >
            <SoftBox position={{xl: "fixed"}} top={{xl:"25%"}}>
              <SoftBox width={{ xl:"75%"}}>
                <img src={image} style={{width:"100%"}} />
              </SoftBox>

              <SoftTypography my={5} variant="body2" fontWeight="regular" color="text">
                Are you having an issue?, 
                contact us <a style={{cursor:"pointer", borderBottom:"1px solid #08ddb8", color:"#08ddb8"  }} href="https://www.credicorp.ng/contact" target="_blank" rel="noreferrer">here </a>
              </SoftTypography>
            </SoftBox>
            {/* <SoftBox
              ml={-9}
              sx={{
                background: `url(${image})`,
                backgroundSize: "cover",
                transform: "skewX(10deg)",
                minHeight:"180vh"
              }}
            >
            <>
              <SoftBox mb={1} sx={{top:"20%", position:"absolute", left:"20%",}}>
                <SoftBox mb={1}>
                  <SoftTypography mb={5} variant="h4" fontWeight="bold" sx={{color:"#fff", width:"300px"}}>
                    { activePage === 1 ? 'Personal Information' :
                      activePage === 2 ? 'Primary Employment Information' :
                      activePage === 3 ? 'Secondary Employment Information' :
                      activePage === 4 ? 'Credit & Identification Info' : 
                      activePage === 5 ? 'Face Capture' : ""
                    }
                  </SoftTypography>
                </SoftBox>
                <SoftTypography variant="body2" fontWeight="regular" color="text" sx={{color:"#fff",  width:"350px"}}>
                    { activePage === 1 ? 'Please enter your personal information and proceed to the next stage.' :
                      activePage === 2 ? 'Please enter your primary employment information and proceed to the next stage.' :
                      activePage === 3 ? 'Please enter your secondary employment information and proceed to the next stage.' :
                      activePage === 4 ? 'Please enter your Credit & Identification Info and proceed to the next stage.' : 
                      activePage === 5 ? 'Good job, Kindly fit in your head into the square and click on capture after which you can proceed or retake the picture .' : ""
                    }
                  
                </SoftTypography>
              </SoftBox>
            </>
            </SoftBox> */}
          </SoftBox>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
  logo: PropTypes.string,
  activePage: PropTypes.number,
};

export default CoverLayout;
