export let localGov =[
    {
      state: 'Abia',
      lgas: [
        'Aba North',         'Aba South',
        'Arochukwu',         'Bende',
        'Ikwuano',           'Isiala-Ngwa North',
        'Isiala-Ngwa South', 'Isuikwuato',
        'Obi Ngwa',           'Ohafia',
        'Osisioma',          
        'Ugwunagbo',         'Ukwa East',
        'Ukwa West',         'Umuahia North',
        'Umuahia South',     'Umu-Neochi'
      ]
    },
    {
      state: 'Abuja',
      lgas: [
        'Abaji',
        'Bwari',
        'Gwagwalada',
        'Kuje',
        'Kwali',
        'Municipal Area Council',
      ]
    },
    {
      state: 'Adamawa',
      lgas: [
        'Demsa',      'Fufore',
        'Ganye',     'Gireri',
        'Gombi',      'Guyuk',
        'Hong',       'Jada',
        'Lamurde',    'Madagali',
        'Maiha',      'Mayo-Belwa',
        'Michika',    'Mubi North',
        'Mubi South', 'Numan',
        'Shelleng',   'Song',
        'Toungo',     'Yola North',
        'Yola South'
      ]
    },
    {
      state: 'Anambra',
      lgas: [
        'Aguata',        'Anambra East',
        'Anambra West',  'Anaocha',
        'Awka North',    'Awka South',
        'Ayamelum',      'Dunukofia',
        'Ekwusigo',      'Idemili North',
        'Idemili south', 'Ihiala',
        'Njikoka',       'Nnewi North',
        'Nnewi South',   'Ogbaru',
        'Onitsha North', 'Onitsha South',
        'Orumba North',  'Orumba South',
        'Oyi'
      ]
    },
    {
      state: 'Akwa Ibom',
      lgas: [
        'Abak',            'Eastern Obolo',
        'Eket',            'Esit Eket',
        'Essien Udim',     'Etim Ekpo',
        'Etinan',          'Ibeno',
        'Ibesikpo Asutan', 'Ibiono Ibom',
        'Ika',             'Ikono',
        'Ikot Abasi',      'Ikot Ekpene',
        'Ini',             'Itu',
        'Mbo',             'Mkpat Enin',
        'Nsit Atai',       'Nsit Ibom',
        'Nsit Ubium',      'Obot Akara',
        'Okobo',           'Onna',
        'Oron',            'Oruk Anam',
        'Udung Uko',       'Ukanafun',
        'Uruan',           'Urue-Offong/Oruko ',
        'Uyo'
      ]
    },
    {
      state: 'Bauchi',
      lgas: [
        'Alkaleri',       'Bauchi',
        'Bogoro',         'Damban',
        'Darazo',         'Dass',
        'Gamawa',         'Ganjuwa',    
        'Giade',          'Itas/Gadau', 
        "Jama'are",       'Katagum',    
        'Kirfi',          'Misau',      
        'Ningi',          'Shira',      
        'Tafawa-Balewa',  'Toro',       
        'Warji',          'Zaki'
      ]
    },
    {
      state: 'Bayelsa',
      lgas: [
        'Brass',
        'Ekeremor',
        'Kolokuma/Opokuma',
        'Nembe',
        'Ogbia',
        'Sagbama',
        'Southern Ijaw',
        'Yenegoa'
      ]
    },
    {
      state: 'Benue',
      lgas: [
        'Ado',         'Agatu',
        'Apa',         'Buruku',
        'Gboko',       'Guma',
        'Gwer East',   'Gwer West',
        'Katsina-Ala', 'Konshisha',
        'Kwande',      'Logo',
        'Makurdi',     'Obi',
        'Ogbadibo',    'Oju',
        'Okpokwu',     'Ohimini',
        'Otukpo',     'Tarka',
        'Ukum',        'Ushongo',
        'Vandeikya'
      ]
    },
    {
      state: 'Borno',
      lgas: [
        'Abadam',   'Askira/Uba', 'Bama',
        'Bayo',     'Biu',        'Chibok',
        'Damboa',   'Dikwa',      'Gubio',
        'Guzamala', 'Gwoza',      'Hawul',
        'Jere',     'Kaga',       'Kala/Balge',
        'Konduga',  'Kukawa',     'Kwaya Kusar',
        'Mafa',     'Magumeri',   'Maiduguri',
        'Marte',    'Mobbar',     'Monguno',
        'Ngala',    'Nganzai',    'Shani'
      ]
    },
    {
      state: 'Cross River',
      lgas: [
        'Akpabuyo', 'Odukpani',
        'Akamkpa',  'Biase',
        'Abi',      'Ikom',
        'Yakuur',   'Obubra',
        'Boki',     'Ogoja',
        'Yala',     'Obanliku',
        'Obudu',    'Calabar South',
        'Etung',    'Bekwara',
        'Bakassi',  'Calabar Municipal'
      ]
    },
    {
      state: 'Delta',
      lgas: [
        'Oshimili South', 'Aniocha North',
        'Aniocha South',  'Ika South',
        'Ika North-East', 'Ndokwa West',
        'Ndokwa East',    'Isoko south',
        'Isoko North',    'Bomadi',
        'Burutu',         'Ughelli South',
        'Ughelli North',  'Ethiope West',
        'Ethiope East',   'Sapele',
        'Okpe',           'Warri North',
        'Warri South',    'Uvwie',
        'Udu',            'Warri Central',
        'Ukwani',         'Oshimili North',
        'Patani'
      ]
    },
    {
      state: 'Ebonyi',
      lgas: [
        'Edda',       'Afikpo South',
        'Afikpo North',
        'Onicha',     'Ohaozara',
        'Abakaliki',  'Ishielu',
        'Ikwo',       'Ezza North',
        'Ezza South', 'Ohaukwu',
        'Ebonyi',     'Ivo',
        'Izzi'
      ]
    },
    {
      state: 'Enugu',
      lgas: [
        'Enugu South,', 'Igbo-Eze South',
        'Enugu North',  'Nkanu West',
        'Awgu',         'Oji River',
        'Ezeagu',       'IgboEze North',
        'Isi Uzo',      'Nsukka',
        'Igbo Etiti',   'Uzo-Uwani',
        'Enugu East',    'Aninri',
        'Nkanu East',   'Udenu.'
      ]
    },
    {
      state: 'Edo',
      lgas: [
        'Akoko Edo',
        'Esan North-East', 'Esan Central',
        'Esan West',       'Egor',
        'Ikpoba Okha',     'Central',
        'Etsako Central',  'Igueben',
        'Oredo',           'Ovia South-West',
        'Ovia South-East', 'Ovia North-East',
        'Orhionwon',
        'Owan East',       'Owan West',
        'Uhunmwonde',      'Etsako East',
        'Etsako West',     'Esan South-East'
      ]
    },
    {
      state: 'Ekiti',
      lgas: [
        'Ado',        'Ekiti-East',
        'Ekiti-West',       'Emure/Ise/Orun',
        'Ekiti South-West', 'Ikere',
        'Irepodun/Ifelodun','Ijero,',
        'Ido/Osi',          'Oye',
        'Ikole',            'Moba',
        'Gbonyin',          'Efon',
        'Ise/Orun',         'Ilejemeje.'
      ]
    },
    {
      state: 'Gombe',
      lgas: [
        'Akko',
        'Balanga',
        'Billiri',
        'Dukku',
        'Kaltungo',
        'Kwami',
        'Shomgom',
        'Funakaye',
        'Gombe',
        'Nafada/Bajoga',
        'Yamaltu/Delta.'
      ]
    },
    {
      state: 'Imo',
      lgas: [
        'Aboh-Mbaise',      'Ahiazu-Mbaise',
        'Ehime-Mbano',      'Ezinihitte',
        'Ideato North',     'Ideato South',
        'Ihitte/Uboma',     'Ikeduru',
        'Isiala Mbano',     'Isu',
        'Mbaitoli',         
        'Ngor-Okpala',      'Njaba',
        'Nwangele',         'Nkwerre',
        'Obowo',            'Oguta',
        'Ohaji/Egbema',     'Onuimo',
        'Okigwe',
        'Orlu',             'Orsu',
        'Oru East',         'Oru West',
        'Owerri-Municipal', 'Owerri North',
        'Owerri West'
      ]
    },
    {
      state: 'Jigawa',
      lgas: [
        'Auyo',         'Babura',
        'Birni Kudu',   'Biriniwa',
        'Buji',         'Dutse',
        'Gagarawa',     'Garki',
        'Gumel',        'Guri',
        'Gwaram',       'Gwiwa',
        'Hadejia',      'Jahun',
        'Kafin Hausa',  'Kaugama',
        'Kazaure',
        'Kiri Kasamma', 'Kiyawa',
        'Maigatari',    'Malam Madori',
        'Miga',         'Ringim',
        'Roni',         'Sule-Tankarkar',
        'Taura',        'Yankwashi'
      ]
    },
    {
      state: 'Kaduna',
      lgas: [
        'Birni-Gwari',  'Chikun',
        'Giwa',         'Igabi',
        'Ikara',        'jaba',
        "Jema'a",       'Kachia',
        'Kaduna North', 'Kaduna South',
        'Kagarko',      'Kajuru',
        'Kaura',        'Kauru',
        'Kubau',        'Kudan',
        'Lere',         'Makarfi',
        'Sabon-Gari',   'Sanga',
        'Soba',         'Zangon-Kataf',
        'Zaria'
      ]
    },
    {
      state: 'Kano',
      lgas: [
        'Ajingi',         'Albasu',   'Bagwai',
        'Bebeji',         'Bichi',    'Bunkure',
        'Dala',           'Dambatta', 'Dawakin Kudu',
        'Dawakin Tofa',   'Doguwa',   'Fagge',
        'Gabasawa',       'Garko',    'Garum',
        'Mallam',         'Gaya',     'Gezawa',
        'Gwale',          'Gwarzo',   'Kabo',
        'Kano Municipal', 'Karaye',   'Kibiya',
        'Kiru',           'kumbotso', 'Ghari',
        'Kunchi',
        'Kura',           'Madobi',   'Makoda',
        'Minjibir',       'Nasarawa', 'Rano',
        'Rimin Gado',     'Rogo',     'Shanono',
        'Sumaila',        'Takali',   'Tarauni',
        'Tofa',           'Tsanyawa', 'Tudun Wada',
        'Ungogo',         'Warawa',   'Wudil'
      ]
    },
    {
      state: 'Katsina',
      lgas: [
        'Bakori',     'Batagarawa', 'Batsari',
        'Baure',      'Bindawa',    'Charanchi',
        'Dandume',    'Danja',      'Dan Musa',
        'Daura',      'Dutsi',      'Dutsin-Ma',
        'Faskari',    'Funtua',     'Ingawa',
        'Jibia',      'Kafur',      'Kaita',
        'Kankara',    'Kankia',     'Katsina',
        'Kurfi',      'Kusada',     "Mai'Adua",
        'Malumfashi', 'Mani',       'Mashi',
        'Matazu',    'Musawa',     'Rimi',
        'Sabuwa',     'Safana',     'Sandamu',
        'Zango'
      ]
    },
    {
      state: 'Kebbi',
      lgas: [
        'Aleiro',       'Arewa-Dandi',
        'Argungu',      'Augi',
        'Bagudo',       'Birnin Kebbi',
        'Bunza',        'Dandi',
        'Fakai',        'Gwandu',
        'Jega',         'Kalgo',
        'Koko/Besse',   'Maiyama',
        'Ngaski',       'Sakaba',
        'Shanga',       'Suru',
        'Wasagu/Danko', 'Yauri',
        'Zuru'
      ]
    },
    {
      state: 'Kogi',
      lgas: [
        'Adavi',          'Ajaokuta',
        'Ankpa',          'Bassa',
        'Dekina',         'Ibaji',
        'Idah',           'Igalamela-Odolu',
        'Ijumu',          'Kabba/Bunu',
        'Kogi',           'Lokoja',
        'Mopa-Muro',      'Ofu',
        'Ogori/Magongo', 'Okehi',
        'Okene',          'Olamaboro',
        'Omala',          'Yagba East',
        'Yagba West'
      ]
    },
    {
      state: 'Kwara',
      lgas: [
        'Asa',          'Baruten',
        'Edu',          'Ekiti',
        'Ifelodun',     'Ilorin East',
        'Ilorin South', 'Ilorin West', 
        'Irepodun',     'Isin',        
        'Kaiama',       'Moro',        
        'Offa',         'Oke-Ero',     
        'Oyun',         'Pategi'
      ]
    },
    {
      state: 'Lagos',
      lgas: [
        'Agege',          'Ajeromi-Ifelodun',
        'Alimosho',       'Amuwo-Odofin',
        'Apapa',          'Badagry',
        'Epe',            'Eti-Osa',
        'Ibeju/Lekki',    'Ifako-Ijaye',
        'Ikeja',          'Ikorodu',
        'Kosofe',         'Lagos Island',
        'Lagos Mainland', 'Mushin',
        'Ojo',            'Oshodi-Isolo',
        'Shomolu',        'Surulere'
      ]
    },
    {
      state: 'Nasarawa',
      lgas: [
        'Akwanga',  'Awe',
        'Doma',     'Karu',
        'Keana',    'Keffi',
        'Kokona',   'Lafia',
        'Nasarawa', 'Nasarawa-Eggon',
        'Obi',      'Toto',
        'Wamba'
      ]
    },
    {
      state: 'Niger',
      lgas: [
        'Agaie',    'Agwara',    'Bida',
        'Borgu',    'Bosso',     'Chanchaga',
        'Edati',    'Gbako',     'Gurara',
        'Katcha',   'Kontagora', 'Lapai',
        'Lavun',    'Magama',    'Mariga',
        'Mashegu',  'Mokwa',     'Moya',
        'Paikoro',  'Rafi',      'Rijau',
        'Shiroro',  'Suleja',    'Tafa',
        'Wushishi'
      ]
    },
    {
      state: 'Ogun',
      lgas: [
        'Abeokuta North', 'Abeokuta South',
        'Ado-Odo/Ota',    'Yewa North',
        'Yewa South',     'Ewekoro',
        'Ifo',            'Ijebu East',
        'Ijebu North',    'Ijebu North East',
        'Ijebu Ode',      'Ikenne',
        'Imeko-Afon',     'Ipokia',
        'Obafemi-Owode',  'Ogun Waterside',
        'Odeda',          'Odogbolu',
        'Remo North',     'Shagamu'
      ]
    },
    {
      state: 'Ondo',
      lgas: [
        'Akoko North East',
        'Akoko North West',
        'Akoko South East',
        'Akoko South West',
        'Akure North',
        'Akure South',
        'Ese-Odo',
        'Idanre',
        'Ifedore',
        'Ilaje',
        'Ile-Oluji/Okeigbo',
        'Irele',
        'Odigbo',
        'Okitipupa',
        'Ondo East',
        'Ondo West',
        'Ose',
        'Owo'
      ]
    },
    {
      state: 'Osun',
      lgas: [
        'Aiyedade',       'Aiyedire',
        'Atakunmosa East', 'Atakunmosa West',
        'Boluwaduro',     'Boripe',
        'Ede North',      'Ede South',
        'Egbedore',       'Ejigbo',
        'Ife Central',    'Ife East',
        'Ife North',      'Ife South',
        'Ifedayo',        'Ifelodun',
        'Ila',            'Ilesha East',
        'Ilesha West',    'Irepodun',
        'Irewole',        'Isokan',
        'Iwo',            'Obokun',
        'Odo-Otin',       'Ola-Oluwa',
        'Olorunda',       'Oriade',
        'Orolu',          'Osogbo'
      ]
    },
    {
      state: 'Oyo',
      lgas: [
        'Afijio',
        'Akinyele',
        'Atiba',
        'Atisbo',
        'Egbeda',
        'Ibadan Central',
        'Ibadan North',
        'Ibadan North East',
        'Ibadan North West',
        'Ibadan South East',
        'Ibadan South West',
        'Ibarapa Central',
        'Ibarapa East',
        'Ibarapa North',
        'Ido',
        'Irepo',
        'Iseyin',
        'Itesiwaju',
        'Iwajowa',
        'Kajola',
        'Lagelu',
        'Ogbomosho North',
        'Ogbomosho South',
        'Ogo Oluwa',
        'Olorunsogo',
        'Oluyole',
        'Ona-Ara',
        'Orelope',
        'Ori Ire',
        'Oyo East',
        'Oyo West',
        'Saki East',
        'Saki West',
        'Surulere'
      ]
    },
    {
      state: 'Plateau',
      lgas: [
        'Barikin Ladi',   'Bassa',
        'Bokkos',         'Jos East',
        'Jos North',      'Jos South',
        'Kanam',          'Kanke',
        'Langtang North', 'Langtang South',
        'Mangu',          'Mikang',
        'Pankshin',       "Qua'an Pan",
        'Riyom',          'Shendam',
        'Wase'
      ]
    },
    {
      state: 'Rivers',
      lgas: [
        'Abua/Odual',  'Ahoada East',
        'Ahoada West', 'Akuku Toru',
        'Andoni',      'Asari-Toru',
        'Bonny',       'Degema',
        'Emuohua',      'Eleme',
        'Etche',       'Gokana',
        'Ikwerre',     'Khana',
        'Obio/Akpor',  'Ogba/Egbema/Ndoni',
        'Ogu/Bolo',    'Okrika',
        'Omuma',      'Opobo/Nkoro',
        'Oyigbo',      'Port-Harcourt',
        'Tai'
      ]
    },
    {
      state: 'Sokoto',
      lgas: [
        'Binji',        'Bodinga',
        'Dange-shuni',  'Gada',
        'Goronyo',      'Gudu',
        'Gawabawa',     'Illela',
        'Isa',          'Kware',
        'kebbe',        'Rabah',
        'Sabon birni',  'Shagari',
        'Silame',       'Sokoto North',
        'Sokoto South', 'Tambuwal',
        'Tangaza',      'Tureta',
        'Wamako',       'Wurno',
        'Yabo'
      ]
    },
    {
      state: 'Taraba',
      lgas: [
        'Ardo-kola', 'Bali',
        'Donga',     'Gashaka',
        'Gassol',    'Ibi',
        'Jalingo',   'Karim Lamido',
        'Kumi',     'Lau',
        'Sardauna',  'Takum',
        'Ussa',      'Wukari',
        'Yorro',     'Zing'
      ]
    },
    {
      state: 'Yobe',
      lgas: [
        'Bade',     'Bursari',
        'Damaturu', 'Fika',
        'Fune',     'Geidam',
        'Gujba',    'Gulani',
        'Jakusko',  'Karasuwa',
        'Karawa',   'Machina',
        'Nangere',  'Nguru',
        'Potiskum',
        'Tarmuwa',   'Yunusari',
        'Yusufari'
      ]
    },
    {
      state: 'Zamfara',
      lgas: [
        'Anka',          'Bakura',
        'Birnin Magaji', 'Bukkuyum',
        'Bungudu',       'Gummi',
        'Gusau',         'Kaura',
        'Namoda',        'Maradun',
        'Maru',          'Shinkafi',
        'Talata Mafara', 'Tsafe',
        'Zurmi'
      ]
    }
  ]