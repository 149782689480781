
export let removeLetters = (val) => {
    let replaceText = val.replace(/([^0-9]+)/g, '')
    return replaceText
}

export let removeNumbers = (val) => {
    let replaceText = val.replace(/([^A-Za-z]+)/g, '')
    return replaceText
}

export let removeSpecialChars = (val) => {
    let replaceText = val.replace(/([^A-Za-z0-9]+)/g, '')
    return replaceText
}

