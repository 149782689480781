import Cookies from "js-cookie";

export const authorizationRedirect = () => {
    //place rentry location.....login
  
    Cookies.remove('accessT');
    Cookies.remove('refreshT');
    
    window.location.assign(window.location.origin + '/authentication/sign-in');
  };
  
  export let serverCodes = [500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511];