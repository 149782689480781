export let subIndustryData = [
    {
      industry: 'General Services/Artisans',
      subIndustry: [
        'Auto Mechanic',	
        'Barbing/Hairdressing',	
        'Bead Makers',
        'Bricklaying',
        'Butcher',	
        	'Carpentry',
        'Food Services (Restaurants, Catering, etc.)',
        'Shoes/Cobbler Services',
        'Courier Services',
        'Dry Cleaning/Laundry',	
        'Electrician and Electronics Repair',
        'Entertainment',
        'Esusu (Ajo) Collector',	
        'Event Planner',
        'Goldsmiths',
        'Locksmiths',
        'Make-Up Artists',
        'Money Lending Services',
        'Painter',
        'Photographer',
        'Plumber',
        'Printing/Publishing',
        'Business Center {e.g. Computer Services, Printing, Scanning, Photocopy etc.}',
        'Property Development',
        'Tailoring',
        'Technician/Equipment Services',
        'Vulcanizer',
        'Welding',
        'Others',
      ]
    },
    {
      industry: 'Specialised Services',
      subIndustry: [
        'Accounting & Auditing',	
        'Architecture and Engineering',
        'Business Consultant',
        'Contracting',
        'Data Processing & Database Activities',
        'Educational Services',
        'Financial Services',
        'Hardware Consultancy',
        'Hotels and Hospitality',	
        'Human Resources, Recruitment and Training',
        'Information Technology (Website Designing, Coding, App etc.)',
        'Insurance Services',
        'Legal Services',	
        'Marketing Strategist',	
        'Media (Others)',	
        'Media (Print)',	
        'Medical Services',	
        'PR Consulting',	
        'Research and Development',
        'Telecommunication',	
        'Others',
      ]
    },
    {
        industry: 'Agriculture (Crop and Livestock)',
      subIndustry: [
        'Crop: Rice',	
        'Crop: Maize',	
        'Crop: Yam',	
        'Crop: Cassava',
        'Crop: Cocoa',
        'Crop: Beans',
        'Crop: Groundnuts',
        'Crop: Soybeans',
        'Crop: Oil palm/Palm kernel',
        'Crop: Millet',
        'Crop: Seasame seed',
        'Crop: Sorghum',
        'Crop: Tomato',
        'crop: sugar cane',
        'Crop: Shea',
        'Crop: Tigernut',
        'Crop: Wheat',
        'Crop: Vegetables (melon, hibiscus, cucumber, leaves, etc)',
        'Crop: Fruits (oranges, mangoes, pineapples, etc)',
        'Crop: Other',
        'Livestock: Poultry',
        'Livestock: Cattle',
        'Livestock: Goat/Ram',
        'Livestock: Fish/Seafood',
        'Livestock: Piggery',
        'Livestock: rabbit',
        'Livestock: snail',
        'Livestock: Leather',
        'Livestock: Other',
        'Agro services: vertinary',
        'Agro services: equipment operators',
        'Agro services: equipment renters',
        'Agro services: equipment sales',
        'Food processing (grinding, cutting, cleaning, packaging)',
        'Agro supplies and chemicals (e.g. Feed, Herbicide, Fungicide, NPK, Insecticide)',
        'Agric Financing',
        'Agric Transportation',
        'Agric Warehousing',
        'Others',
    ]
    },
    {
        industry: 'Transportation/Logistics',
      subIndustry: [
        'Bus Transportation',	
        'Dispatch Riders',
        'General Logistics/Haulage',
        'Keke/Tricycle Transportation',
        'Okada/Motorcycle Transportation',
        'Taxi Transportation',
        'Truck Transportation',
        'Wheelbarrow and Cart pushers',
        'Others',
      ]
    },
    {
        industry: 'Trading',
      subIndustry: [
        'Aluminium, Metal, Iron & Steel',
        'Automobile (sales, spare parts, scrap)',
        'Building Materials',
        'Cables and Wires',
        'Chemicals',
        'Clothing and Fashion Accessories (Jewelry, etc)',
        'Computers and Accessories',
        'Drinks (Alcoholic and Non-Alcoholic)',
        'Electronics and Electrical Appliances',
        'Farm input',
        'Farm machinery',
        'Foams and Mattresses',
        'Food Items and Processed Food',
        'Footwear',
        'Forex Trading',
        'Furniture',
        'Oil (Edible Cooking)',
        'Paint',
        'Personal Care Products (Cream, Cosmetics, Soap, Sanitary Wear)',
        'Petroleum Products (Petrol, Diesel, Gas, Kerosine, Engine, Brake)',
        'Pharmaceuticals (Drugs, etc)',
        'Plastics & Packaging',
        'Shoes and Bags',
        'Stationery (Books, Paper, Pens, Writing Products)',
        'Supermarket',
        'Telco Services/Call Centre/Airtime',
        'Textiles',
        'Tiles & Marbles',
        'Tyres',
        'Wood',
        'Others',
      ]
    },
    {
        industry: 'Manufacturing',
      subIndustry: [
        'Aluminium, Metal, Iron & Steel',	
        'Automobile (Assembing, Spare Parts)',	
        'Drinks (Alcoholic and Non-Alcoholic)',	
        'Bakery and Confectioneries',
        'Building Materials',	
        'Cables and Wires',	
        'Chemicals',	
        'Computers & Accessories',	
        'Detergents and Cleaning Agents',	
        'Domestic Gas',
        'Electronics and Electrical Appliances',
        'Clothing and Fashion Accessories (Jewelry, Bags, etc)',
        'Foams and Mattresses',	
        'Food Manufacturing and Processing',	
        'Footwear',	
        'Furniture',	
        'Leather and Related Materials',	
        'Oil (Edible Cooking)',	
        'Paint',	
        'Personal Care Products (Cream, Cosmetics, Soap, Sanitary Ware)',	
        'Petroleum Products (Petrol, Diesel, Gas, Kerosine, Engine, Brake)',	
        'Pharmaceuticals (Drugs, Herbs, Traditional, etc)',	
        'Plastics & Packaging',	
        'Shoes and Bags',	
        'Stationery (Books, Paper, Pens, Writing Products)',	
        'Textiles',	
        'Tiles and Marbles',	
        'Tyres and Rubber',	
        'Wood Planting & Processing',	
        'Others	'
      ]
    },
]