import { CircularProgress } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
// import { Spinner } from 'react-bootstrap';
import Webcam from 'react-webcam';
// import { EmptyStateComp } from '../../../../../utils';

function FaceCaptureComp(props) {
  const webcamRef = useRef();
  const captchaRef = useRef(null)
  const [faceImg, setFaceImg] = useState('');
  const [cameraError, setCameraError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFaceImg(imageSrc);
  }, [webcamRef]);


  function onChange(value) {
    setRecaptchaToken(value);
  }
  // console.log(captchaRef)

  let retakeImage = () => {
    setFaceImg('');
  };

  let proceedToNext = async () => {
    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();
    // console.log(token)


    // grecaptcha.execute('6LfmkscpAAAAAHeWV6s4jrkb5pw10uQ90ZFYeQiM', {action: 'submit'}).then(function(token) {
    //   console.log(token);
    //     // Add your logic to submit to your backend server here.
    // });

    props?.proceedFromFaceCapture(faceImg);
  };

  const videoConstraints = {
    width: 320,
    height: 300,
    facingMode: 'user'
  };

  //   <script type="text/javascript">
  //   var onloadCallback = function() {
  //     grecaptcha.render('html_element', {
  //       'sitekey' : ' 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  //     });
  //   };
  // </script>
  //   <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
  //   async defer>
  // </script>


  return (
    <div>
      <div className="">
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          {/* This application requires your face to proceed;  */}
          Please capture your face here <span style={{ fontWeight: "800" }}>(Optional)</span> .
        </SoftTypography>
        <SoftBox mt={3} mb={3}>
          <SoftButton variant="outlined" color="info" onClick={() => props?.goToPrevious()}>
            Back to form
          </SoftButton>
        </SoftBox>
      </div>

      <div className="col-md-6 ">
        {cameraError && (
          <div className="">
            <div className="" style={{ textAlign: "left", }}>
              <SoftTypography my={4} variant="body2" fontWeight="regular" color="text">
                There seem to be an error with your camera.
              </SoftTypography>

              {/* <SoftBox mb={4}>
                <ReCAPTCHA
                  sitekey={"6LfmkscpAAAAAHeWV6s4jrkb5pw10uQ90ZFYeQiM"}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  ref={captchaRef}
                  onChange={onChange}
                // size="invisible"
                />
              </SoftBox> */}


              {/* {recaptchaToken && */}
                <SoftButton mt={4} variant="contained" color="info" onClick={proceedToNext} sx={{ width: "45%" }}>
                  {props?.initiateLoading ? (
                    <span>
                      <CircularProgress color="inherit" />
                      Loading...
                    </span>
                  ) : (
                    'Proceed'
                  )}
                </SoftButton>
              {/* } */}
            </div>
          </div>
        )}

        {!faceImg && (
          <>
            <Webcam
              audio={false}
              height={300}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              videoConstraints={videoConstraints}
              onUserMediaError={() => setCameraError(true)}
              onUserMedia={() => setCameraError(false)}
            />

              {/* <SoftBox mb={4} mt={4}>
                <ReCAPTCHA
                  sitekey={"6LfmkscpAAAAAHeWV6s4jrkb5pw10uQ90ZFYeQiM"}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  ref={captchaRef}
                  onChange={onChange}
                // size="invisible"
                />
              </SoftBox> */}

            {!cameraError && (
              <div>
                <SoftBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <SoftButton variant="outlined" color="info" onClick={capture}
                    sx={{ width: "45%" }}
                  >
                    Capture photo
                  </SoftButton>

                  {/* {recaptchaToken && */}
                    <SoftButton variant="contained" color="info" onClick={proceedToNext} sx={{ width: "45%" }}>
                      {props?.initiateLoading ? (
                        <span>
                          <CircularProgress color="inherit" />
                          Loading...
                        </span>
                      ) : (
                        'Proceed'
                      )}
                    </SoftButton>
                  {/* } */}

                </SoftBox>

                {/* <SoftButton variant="contained" color="info" onClick={capture}>
                  Capture photo
                </SoftButton> */}
              </div>
            )}

          </>
        )}

        {faceImg && (
          <>
            <img src={faceImg} style={{ borderRadius: '50%', width: '300px', height: '300px' }} alt="" />

              {/* <SoftBox mb={4} mt={4}>
                <ReCAPTCHA
                  sitekey={"6LfmkscpAAAAAHeWV6s4jrkb5pw10uQ90ZFYeQiM"}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  ref={captchaRef}
                  onChange={onChange}
                // size="invisible"
                />
              </SoftBox> */}

            <SoftBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
              <SoftButton variant="outlined" color="info" onClick={retakeImage}
                sx={{ width: "45%" }}
              >
                Retake
              </SoftButton>

              {/* {recaptchaToken && */}
                <SoftButton variant="contained" color="info" onClick={proceedToNext} sx={{ width: "45%" }}>
                  {props?.initiateLoading ? (
                    <span>
                      <CircularProgress color="inherit" />
                      Loading...
                    </span>
                  ) : (
                    'Proceed'
                  )}
                </SoftButton>
              {/* } */}
            </SoftBox>
          </>
        )}
      </div>
    </div>
  );
}

FaceCaptureComp.propTypes = {
  proceedFromFaceCapture: PropTypes.func,
  initiateLoading: PropTypes.bool,
  goToPrevious: PropTypes.func,
  color: PropTypes.string,
};

export default FaceCaptureComp;
