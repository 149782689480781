/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import { Autocomplete, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
// import curved9 from "assets/images/curved-images/curved-6.jpg";
// import appImg from "assets/images/curved-images/application-img.png";
// import ccImg from "assets/images/curved-images/cc-ill.jpg";
import ccImg from "assets/images/curved-images/cc-img.png";
// import sideImgOne from "assets/images/curved-images/side-1.png";
// import sideImgTwo from "assets/images/curved-images/side-2.png";
// import sideImgThree from "assets/images/curved-images/side-3.png";
// import sideImgFour from "assets/images/curved-images/side-4.png";
import logo from "assets/images/app-logo.jpeg"
import SoftAlert from "components/SoftAlert";
import { toast } from "react-toastify";
import FaceCaptureComp from "./faceCapture";
import { localGov } from "components/utils/lg";
// import { ministryData } from "components/utils/ministryParastatal";
// import { parastatalData } from "components/utils/ministryParastatal";
import { subIndustryData } from "components/utils/subIndustry";
import { ministryParastatalData } from "components/utils/ministryParastatal";
import { nigeriaBanks } from "components/utils/banks";
import { removeNumbers } from "components/utils";
import { removeLetters } from "components/utils";
import { allParastatalData } from "components/utils/ministryParastatal";
import { removeSpecialChars } from "components/utils";
import { DayPicker, MonthPicker, YearPicker } from "@hirobreak/react-dropdown-date";
import { apiLinks } from "../../../config/environment.js";



function Apply() {
    const [rememberMe, setRememberMe] = useState(true);
    const [page, setPage] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('your answer');
    const [dob, setDob] = useState('');
    const [dayOfBirth, setDayOfBirth] = useState('');
    const [monthOfBirth, setMonthOfBirth] = useState('');
    const [yearOfBirth, setYearOfBirth] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [originState, setOriginState] = useState('your answer');
    const [lgData, setLgData] = useState([]);
    const [originLG, setOriginLG] = useState('your answer');
    const [residenceState, setResidenceState] = useState('your answer');
    const [residenceLgData, setResidenceLgData] = useState([]);
    const [residenceLG, setResidenceLG] = useState('your answer');

    const [employmentType, setEmploymentType] = useState('your answer');
    const [gradeLevel, setGradeLevel] = useState('your answer');
    const [serviceNumber, setServiceNumber] = useState('');
    const [ministry, setMinistry] = useState('your answer');
    const [paraConfirm, setParaConfirm] = useState('');
    const [parastatalData, setParastatalData] = useState([]);
    const [parastatal, setParastatal] = useState('your answer');
    const [paraSearch, setParaSearch] = useState('');
    const [schoolState, setSchoolState] = useState('your answer');
    const [schoolName, setSchoolName] = useState('');
    const [industry, setIndustry] = useState('your answer');
    const [subIndustryDataData, setSubIndustryDataData] = useState([]);
    const [subIndustry, setSubIndustry] = useState('your answer');
    // const [otherData, setOtherData] = useState('');

    // const [civilDepartment, setCivilDepartment] = useState('');
    const [employmentYears, setEmploymentYears] = useState('');
    const [salaryRange, setSalaryRange] = useState('');
    const [bankName, setBankName] = useState('your answer');
    const [accountNumber, setAccountNumber] = useState('');
    // const [bvnData, setBvnData] = useState('');


    const [secEmployment, setSecEmployment] = useState('');
    const [secEmploymentType, setSecEmploymentType] = useState('your answer');
    const [secGradeLevel, setSecGradeLevel] = useState('your answer');
    const [secServiceNumber, setSecServiceNumber] = useState('');
    const [secMinistry, setSecMinistry] = useState('your answer');
    const [secParaConfirm, setSecParaConfirm] = useState('');
    const [secParastatalData, setSecParastatalData] = useState([]);
    const [secParastatal, setSecParastatal] = useState('your answer');
    const [secParaSearch, setSecParaSearch] = useState('');
    const [secSchoolState, setSecSchoolState] = useState('your answer');
    const [secSchoolName, setSecSchoolName] = useState('');
    const [secIndustry, setSecIndustry] = useState('your answer');
    const [secSubIndustryDataData, setSecSubIndustryDataData] = useState([]);
    const [secSubIndustry, setSecSubIndustry] = useState('your answer');
    // const [secOtherData, setSecOtherData] = useState('');
    const [secEmploymentYears, setSecEmploymentYears] = useState('');
    const [secSalaryRange, setSecSalaryRange] = useState('');


    const [mostImportantNeed, setMostImportantNeed] = useState('your answer');
    const [secondMostImportantNeed, setSecondMostImportantNeed] = useState('your answer');
    const [thirdMostImportantNeed, setThirdMostImportantNeed] = useState('your answer');
    const [creditAmount, setCreditAmount] = useState('');
    const [everBorrowed, setEverBorrowed] = useState('')
    const [highestEverBorrowed, setHighestEverBorrowed] = useState('')

    const [ninData, setNinData] = useState('');
    const [doc_image, setDocImage] = useState(null);
    const [docFormat, setDocFormat] = useState('');
    const [consent, setConsent] = useState('');

    const [loading, setLoading] = useState(false);

    // const handleSetRememberMe = () => setRememberMe(!rememberMe);


    let next = () => {
        let phone_reg = /^(090|091|070|080|081)\d{8}$/;
        let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

        if (page === 1) {
            if (!firstName) {
                toast.error('Please Input Your First Name');
                return;
            }
            if (!middleName) {
                toast.error('Please Input Your Middle Name');
                return;
            }
            if (!lastName) {
                toast.error('Please Input Your Last Name');
                return;
            }
            if (!email) {
                toast.error('Please Input Your Email');
                return;
            }
            if (email && email_reg.test(email) === false) {
                toast.error('Email Address is Invalid');
                return;
            }
            if (!phone) {
                toast.error('Please Input Your Phone Number');
                return;
            }
            if (phone && !phone_reg?.test(phone)) {
                toast.error('Please Input a Valid Nigerian Number');
                return;
            }
            if (!gender || gender === "your answer") {
                toast.error('Please Select Your Gender');
                return;
            }
            // if (!dob) {
            //     toast.error('Please Input Your Date Of Birth');
            //     return;
            // }
            if (!dayOfBirth) {
                toast.error('Please Input Your Day Of Birth');
                return;
            }
            if (!monthOfBirth) {
                toast.error('Please Input Your Month Of Birth');
                return;
            }
            if (!yearOfBirth) {
                toast.error('Please Input Your Year Of Birth');
                return;
            }
            if ((new Date(dob).getFullYear()) > 2006) {
                toast.error('Your age cannot be less than 18years');
                return;
            }
            if ((!originState || originState === "your answer")) {
                toast.error('Please select your state of origin');
                return;
            }
            if ((!originLG || originLG === "your answer")) {
                toast.error('Please enter your local government of origin');
                return;
            }
            if ((!residenceState || residenceState === "your answer")) {
                toast.error('Please select your state of residence');
                return;
            }
            if ((!residenceLG || residenceLG === "your answer")) {
                toast.error('Please enter your local government of residence');
                return;
            }
            setPage(2);
        }

        if (page === 2) {
            if (!mostImportantNeed || mostImportantNeed === "your answer") {
                toast.error('Please select your most important need');
                return;
            }
            if (!secondMostImportantNeed || secondMostImportantNeed === "your answer") {
                toast.error('Please select what else you need credit for');
                return;
            }
            if (!thirdMostImportantNeed || thirdMostImportantNeed === "your answer") {
                toast.error('Please select what else you need credit for');
                return;
            }
            if (!creditAmount) {
                toast.error('Please input the amount you can borrow today and pay back comfortably over 6 months');
                return;
            }
            if ((parseInt(creditAmount) > 10000000)) {
                toast.error('Credit amount must be less than 10million');
                return;
            }
            if (!everBorrowed) {
                toast.error('Please specify if you have ever borrowed money from a bank, microfinance, fintech, or loan app');
                return;
            }
            if (everBorrowed && (everBorrowed !== "No") && !highestEverBorrowed) {
                toast.error('Please input the highest amount of money you have ever borrowed');
                return;
            }
            if (!ninData) {
                toast.error('Please input your NIN');
                return;
            }
            if (ninData?.length !== 11) {
                toast.error('Please input a valid NIN');
                return;
            }
            // if (!doc_image) {
            //     toast.error('Please upload your Government issued ID Card');
            //     return;
            // }
            // if (doc_image?.type !== 'image/png' && doc_image?.type !== 'image/jpeg' && doc_image?.type !== 'image/jpg') {
            //     toast.error('Please make sure you are uploading the right image formats');
            //     return;
            // }
            // if (doc_image.size > 1100000) {
            //     toast.error('Please make sure your ID Card size is not bigger than 1mb');
            //     return;
            // }
            setPage(3);
        }

        if (page === 3) {
            if (!employmentType || (employmentType === "your answer")) {
                toast.error('Please select your employment type');
                return;
            }
            if ((!gradeLevel || gradeLevel === "your answer") && (employmentType === "State civil service")) {
                toast.error('Please select your grade level');
                return;
            }
            // if ((employmentType === "State civil service") && !serviceNumber) {
            //     toast.error('Please enter your IPPIS number');
            //     return;
            // }
            if ((!gradeLevel || gradeLevel === "your answer") && (employmentType === "Federal civil service")) {
                toast.error('Please select your grade level');
                return;
            }
            // if ((employmentType === "Federal civil service") && !serviceNumber) {
            //     toast.error('Please enter your IPPIS number');
            //     return;
            // }
            if ((!ministry || ministry === "your answer") && (employmentType === "Federal civil service")) {
                toast.error('Please select your ministry');
                return;
            }
            if ((employmentType === "Federal civil service") && (!parastatal || parastatal === "your answer") && (paraConfirm === "yes")) {
                toast.error('Please select your your parastatal');
                return;
            }
            // if ((employmentType === "Federal civil service") && (parastatal === "all") && (!paraSearch)) {
            //     toast.error('Please select your your parastatal');
            //     return;
            // }

            if ((employmentType === "Student") && (!schoolState || schoolState === "your answer")) {
                toast.error('Please select your school state');
                return;
            }
            if ((employmentType === "Student") && !schoolName) {
                toast.error('Please enter your school name');
                return;
            }

            if ((employmentType === "Entrepreneur") && (!industry || industry === "your answer")) {
                toast.error('Please select your industry');
                return;
            }
            if ((employmentType === "Entrepreneur") && !subIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            if ((employmentType === "Executive") && (!industry || industry === "your answer")) {
                toast.error('Please select your industry');
                return;
            }
            if ((employmentType === "Executive") && !subIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            if ((employmentType === "Company") && (!industry || industry === "your answer")) {
                toast.error('Please select your industry');
                return;
            }
            if ((employmentType === "Company") && !subIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            if ((employmentType === "Farmer") && !subIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            // if ((employmentType === "Others") && !otherData) {
            //     toast.error('Please input your employment type');
            //     return;
            // }

            if (employmentType !== "Student" && !employmentYears) {
                toast.error('Please input your number of years in employment');
                return;
            }
            if (employmentType !== "Student" && !salaryRange) {
                toast.error('Please input how much you earn per month from your employment');
                return;
            }
            if (employmentType !== "Student" && (parseInt(salaryRange) > 10000000)) {
                toast.error('Salary must be less than 10million');
                return;
            }
            if (!bankName || bankName === "your answer") {
                toast.error('Please input your bank name');
                return;
            }
            if (!accountNumber) {
                toast.error('Please input your account number');
                return;
            }
            if (accountNumber?.length !== 10) {
                toast.error('Please input a valid account number');
                return;
            }
            // if (!bvnData) {
            //     toast.error('Please input your BVN');
            //     return;
            // }
            // if (bvnData?.length !== 11) {
            //     toast.error('Please input a valid BVN');
            //     return;
            // }
            setPage(4);
        }

        if (page === 4) {
            if (!secEmployment) {
                toast.error('Please indicate if you have a SECONDARY employment/side hustle');
                return;
            }
            if ((secEmployment === "Yes") && (!secEmploymentType || (secEmploymentType === "your answer"))) {
                toast.error('Please select your employment type');
                return;
            }
            if ((secEmployment === "Yes") && (!secGradeLevel || secGradeLevel === "your answer") && (secEmploymentType === "State civil service")) {
                toast.error('Please select your grade level');
                return;
            }
            // if ((secEmployment === "Yes") && (secEmploymentType === "State civil service") && !secServiceNumber) {
            //     toast.error('Please enter your IPPIS number');
            //     return;
            // }
            if ((secEmployment === "Yes") && (!secGradeLevel || secGradeLevel === "your answer") && (secEmploymentType === "Federal civil service")) {
                toast.error('Please select your grade level');
                return;
            }
            // if ((secEmployment === "Yes") && (secEmploymentType === "Federal civil service") && !secServiceNumber) {
            //     toast.error('Please enter your IPPIS number');
            //     return;
            // }
            if ((secEmployment === "Yes") && (!secMinistry || secMinistry === "your answer") && (secEmploymentType === "Federal civil service")) {
                toast.error('Please select your ministry');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType === "Federal civil service") && (!secParastatal || secParastatal === "your answer") && (secParaConfirm === "yes")) {
                toast.error('Please select your your parastatal');
                return;
            }
            // if ((secEmployment === "Yes") && (secEmploymentType === "Federal civil service") && (secParastatal === "all") && (!secParaSearch)) {
            //     toast.error('Please select your your parastatal');
            //     return;
            // }

            if ((secEmployment === "Yes") && (secEmploymentType === "Student") && (!secSchoolState || secSchoolState === "your answer")) {
                toast.error('Please select your school state');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType === "Student") && !secSchoolName) {
                toast.error('Please enter your school name');
                return;
            }

            if ((secEmployment === "Yes") && (secEmploymentType === "Entrepreneur") && (!secIndustry || secIndustry === "your answer")) {
                toast.error('Please select your industry');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType === "Entrepreneur") && !secSubIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            if ((secEmployment === "Yes") && (secEmploymentType === "Executive") && (!secIndustry || secIndustry === "your answer")) {
                toast.error('Please select your industry');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType === "Executive") && !secSubIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            if ((secEmployment === "Yes") && (secEmploymentType === "Company") && (!secIndustry || secIndustry === "your answer")) {
                toast.error('Please select your industry');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType === "Company") && !secSubIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            if ((secEmployment === "Yes") && (secEmploymentType === "Farmer") && !secSubIndustry) {
                toast.error('Please input your sub industry');
                return;
            }

            // if ((secEmployment === "Yes") && (secEmploymentType === "Others") && !secOtherData) {
            //     toast.error('Please input your employment type');
            //     return;
            // }

            if ((secEmployment === "Yes") && (secEmploymentType !== "Student") && !secEmploymentYears) {
                toast.error('Please input your number of years in employment');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType !== "Student") && !secSalaryRange) {
                toast.error('Please input how much you earn per month from your SECONDARY employment');
                return;
            }
            if ((secEmployment === "Yes") && (secEmploymentType !== "Student") && (parseInt(secSalaryRange) > 10000000)) {
                toast.error('Salary must be less than 10million');
                return;
            }
            if (!consent) {
                toast.error('Kindly agree to the terms and conditions');
                return;
            }
            setPage(5);
        }
        // if (page === 5) {
        //   if (!doc_image) {
        //     toast.error('Please upload your Student ID Card');
        //     return;
        //   }
        //   if (doc_image?.type !== 'image/png' && doc_image?.type !== 'image/jpeg' && doc_image?.type !== 'image/jpg') {
        //     toast.error('Please make sure you are uploading the right image formats');
        //     return;
        //   }
        //   if (doc_image.size > 1100000) {
        //     toast.error('Please make sure your ID Card size is not bigger than 1mb');
        //     return;
        //   }
        //   if (!consent) {
        //     toast.error('Kindly agree to the terms and conditions');
        //     return;
        //   }
        //   // pushAllData();
        // }
    };


    let prev = () => {
        if (page === 2) {
            setPage(1);
        }
        if (page === 3) {
            setPage(2);
        }
        if (page === 4) {
            setPage(3);
        }
        if (page === 5) {
            setPage(4);
        }
    };

    // const handleClose = () => {
    //   setSuccessModal(false);
    //   clearData();
    //   setPage(1);
    // };

    // let clearData = () => {
    //   setFirstName('');
    //   setLastName('');
    //   setGender('');
    //   setDob('');
    //   setEmail('');
    //   setPhone('');

    //   setBusName('');
    //   setBusProd('');
    //   setBusAge('');
    //   setSocialAvailability('');
    //   setBusLink('');
    //   setBusRegistration('');
    //   setBusCompetitors('');
    //   setComments('');
    //   setDocFormat('');
    // };



    let getBase64 = (e) => {
        setDocImage(e.target.files[0]);
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setDocFormat(reader.result);
        };
    };

    let initiateReg = (val) => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                gender: gender,
                // dob: dob,
                dob:(!dayOfBirth || !monthOfBirth || !yearOfBirth) ? "" : `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
                email: email,
                phone: phone,
                // civil_service_department: civilDepartment,
                // number_of_years_in_employment: employmentYears,
                // salary_range: salaryRange,
                bank_name: bankName,
                // bvn: bvnData,
                nin: ninData,


                state_of_origin: originState,
                lga_of_origin: originLG,

                state_of_residence: residenceState,
                lga_of_residence: residenceLG,


                primary_employment: employmentType === "your answer" ? "" : employmentType,
                state_civil_service_level: employmentType === "State civil service" ? gradeLevel : "",
                state_civil_service_number: employmentType === "State civil service" ? serviceNumber : "",
                federal_civil_service_level: employmentType === "Federal civil service" ? gradeLevel : "",
                federal_civil_service_number: employmentType === "Federal civil service" ? serviceNumber : "",
                federal_ministry_you_work: ministry === "your answer" ? "" : ministry,
                federal_parastatal_or_agency: parastatal === "your answer" ? "" : parastatal === "all" ? paraSearch : parastatal,
                student_state_of_school: schoolState === "your answer" ? "" : schoolState,
                student_school_name: schoolName,
                enterprenuer_industry: employmentType === "Entrepreneur" ? industry : "",
                enterprenuer_sub_industry: employmentType === "Entrepreneur" ? subIndustry : "",
                executive_industry: employmentType === "Executive" ? industry : "",
                executive_sub_industry: employmentType === "Executive" ? subIndustry : "",
                company_industry: employmentType === "Company" ? industry : "",
                company_sub_industry: employmentType === "Company" ? subIndustry : "",
                farmer_industry: employmentType === "Farmer" ? "Farmer" : "",
                farmer_sub_industry: employmentType === "Farmer" ? subIndustry : "",
                years_of_employment: employmentType === "Student" ? "" : employmentYears,
                primary_employment_salary: employmentType === "Student" ? "" : salaryRange,
                account_number: accountNumber,
                secondary_employment: secEmployment === "Yes" ? true : false,
                secondary_employment_title: secEmploymentType === "your answer" ? "" : secEmploymentType,
                secondary_state_civil_service_level: secEmploymentType === "State civil service" ? secGradeLevel : "",
                secondary_state_civil_service_number: secEmploymentType === "State civil service" ? secServiceNumber : "",
                secondary_federal_civil_service_level: secEmploymentType === "Federal civil service" ? secGradeLevel : "",
                secondary_federal_civil_service_number: secEmploymentType === "Federal civil service" ? secServiceNumber : "",
                secondary_federal_ministry_you_work: secMinistry === "your answer" ? "" : secMinistry,
                secondary_federal_parastatal_or_agency: secParastatal === "your answer" ? "" : secParastatal === "all" ? secParaSearch : secParastatal,
                secondary_student_state_of_school: secSchoolState === "your answer" ? "" : secSchoolState,
                secondary_student_school_name: secSchoolName,
                secondary_enterprenuer_industry: secEmploymentType === "Entrepreneur" ? secIndustry : "",
                secondary_enterprenuer_sub_industry: secEmploymentType === "Entrepreneur" ? secSubIndustry : "",
                secondary_executive_industry: secEmploymentType === "Executive" ? secIndustry : "",
                secondary_executive_sub_industry: secEmploymentType === "Executive" ? secSubIndustry : "",
                secondary_company_industry: secEmploymentType === "Company" ? secIndustry : "",
                secondary_company_sub_industry: secEmploymentType === "Company" ? secSubIndustry : "",
                secondary_farmer_industry: secEmploymentType === "Farmer" ? "Farmer" : "",
                secondary_farmer_sub_industry: secEmploymentType === "Farmer" ? secSubIndustry : "",
                secondary_years_of_employment: secEmploymentType === "Student" ? "" : secEmploymentYears,
                secondary_employment_salary: secEmploymentType === "Student" ? "" : secSalaryRange,
                need_for_credit: mostImportantNeed,
                need_for_credit_2: secondMostImportantNeed,
                need_for_credit_3: thirdMostImportantNeed,
                borrow_and_payback: creditAmount,
                ever_borrowed: everBorrowed === "Yes" ? true : false,
                highest_ever_borrowed: highestEverBorrowed,

                consent_to_credit_check: consent === "Yes" ? true : false,
                consent_to_use_data: consent === "Yes" ? true : false,



                image: val
                    ?.replaceAll('data:image/png;base64,', '')
                    .replaceAll('data:image/jpg;base64,', '')
                    .replaceAll('data:image/jpeg;base64,', ''),
                // idimage: docFormat
                //     ?.replaceAll('data:image/png;base64,', '')
                //     .replaceAll('data:image/jpg;base64,', '')
                //     .replaceAll('data:image/jpeg;base64,', '')
            }),
            redirect: 'follow'
        };

        // fetch('https://2s4ksrmrba.execute-api.us-east-2.amazonaws.com/default/credit-corp-backend-production/api/v1/application/new', requestOptions)
        fetch(`${apiLinks().url}application/new`, requestOptions)
            .then((response) => response?.json())
            .then((result) => {
                setLoading(false);
                if (result?.status) {
                    // toast.success(result?.message);
                    window.location.href = `${window.location.origin}/apply/success`;
                    // setTimeout(() => {
                    //     // window.location.href = `${window.location.origin}/authentication/apply/otp/${result?.data?.application}`;
                    //     window.location.href = `${window.location.origin}/authentication/apply/success`;
                    // }, 2000);
                } else {
                    Object?.entries(result?.errors)?.forEach((key, val) => {
                        toast.error(key[1][0]);
                    })
                }
            })
            .catch((error) => {
                setLoading(false);
                if (!error?.Success) {
                    toast.error(error?.message);
                }
            });
    };


    let getLG = (key, type) => {
        localGov?.forEach((val) => {
            if ((val?.state === key) && type === "ORIGIN") {
                setLgData(val?.lgas)
            }
            if ((val?.state === key) && type === "RESIDENCE") {
                setResidenceLgData(val?.lgas)
            }
        })
    }

    let getSubIndustry = (key, type) => {
        subIndustryData?.forEach((val) => {
            if ((val?.industry === key) && (type === "MAIN")) {
                setSubIndustryDataData(val?.subIndustry)
            }
            if ((val?.industry === key) && (type === "SECONDARY")) {
                setSecSubIndustryDataData(val?.subIndustry)
            }
        })
    }

    let getParastatalData = (key, type) => {
        ministryParastatalData?.forEach((val) => {
            if ((val?.ministry === key) && (type === "MAIN")) {
                setParastatalData(val?.parastatal)
            }
            if ((val?.ministry === key) && (type === "SECONDARY")) {
                setSecParastatalData(val?.parastatal)
            }
        })
    }


    return (
        <CoverLayout
            title="Expression of Interest"
            description={
                page === 1 ? 'Personal Information' :
                    page === 2 ? 'Credit Needs' :
                        page === 3 ? 'Primary Employment ' :
                            page === 4 ? 'Secondary Employment ' :
                                page === 5 ? 'Face Capture' : ""
            }
            // image={page === 1 ? sideImgOne : page === 2 ? sideImgTwo : page === 3 ? sideImgThree : page === 4 ? sideImgFour :  appImg}
            image={ccImg}
            logo={logo}
            activePage={page}
        >

            <div className="form-steps">
                <small className={page >= 1 ? "active" : ""} onClick={() => setPage(1)}>1</small>
                <small className={page >= 2 ? "active" : ""} onClick={() => setPage(2)}>2</small>
                <small className={page >= 3 ? "active" : ""} onClick={() => setPage(3)}>3</small>
                <small className={page >= 4 ? "active" : ""} onClick={() => setPage(4)}>4</small>
                <small className={page >= 5 ? "active" : ""} onClick={() => setPage(5)}>5</small>
            </div>
            <div className="form-steps-name">
                <small className="" onClick={() => setPage(1)}>Personal Information</small>
                <small className="" onClick={() => setPage(2)}>Credit Needs</small>
                <small className="" onClick={() => setPage(3)}>Primary Employment</small>
                <small className="" onClick={() => setPage(4)}>Secondary Employment</small>
                <small className="" onClick={() => setPage(5)}>
                    Face Capture (Optional)
                    {/* <span style={{fontWeight:"600"}}>(Optional)</span>  */}
                </small>
            </div>

            <SoftBox component="form" role="form">
                {page === 1 && (
                    <>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your first name?
                            </SoftTypography>
                            <SoftInput
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(removeNumbers(e.target.value))}
                                placeholder="Firstname"
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your middle name?
                            </SoftTypography>
                            <SoftInput
                                type="text"
                                value={middleName}
                                onChange={(e) => setMiddleName(removeNumbers(e.target.value))}
                                placeholder="MiddleName"
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your surname?
                            </SoftTypography>
                            <SoftInput
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(removeNumbers(e.target.value))}
                                placeholder="Lastname"
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your email?
                            </SoftTypography>
                            <SoftInput
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Phone Number
                            </SoftTypography>
                            <SoftInput
                                type="tel"
                                inputProps={{ maxLength: 11 }}
                                value={phone}
                                onChange={(e) => setPhone(removeLetters(e.target.value))}
                                placeholder="08012345678"
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your gender ?
                            </SoftTypography>
                            <select style={{ width: "100%" }}
                                value={gender}
                                placeholder="Select Gender"
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <option value={"your answer"}>Select you gender</option>
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                            </select>
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your date of birth ?
                            </SoftTypography>
                            {/* <SoftInput
                                type="date"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                placeholder="Date Of Birth"
                            /> */}
                            <div style={{display:"flex", alignItems:"center"}}>

                                <select 
                                    value={dayOfBirth}
                                    onChange={(day) => {
                                        setDayOfBirth(day.target.value)
                                    }}
                                >
                                    <option value={""}>Day</option>
                                    {["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17",
                                        "18","19","20","21","22","23","24","25","26","27","28","29","30","31"].map((val, i) => (
                                        <option key={i} value={val}>{val}</option>
                                    ))}
                                </select>
                                ---
                                <select 
                                    value={monthOfBirth}
                                    onChange={(month) => {
                                        setMonthOfBirth(month.target.value)
                                    }}
                                >
                                    <option value={""}>Month</option>
                                    {[{month:"January", code:"01"},{month:"February", code:"02"},{month:"March", code:"03"},
                                        {month:"April", code:"04"},{month:"May", code:"05"},{month:"June", code:"06"},
                                        {month:"July", code:"07"},{month:"August", code:"08"},{month:"September", code:"09"},
                                        {month:"October", code:"10"},{month:"November", code:"11"},{month:"December", code:"12"},
                                    ].map((val, i) => (
                                        <option key={i} value={val?.code}>{val?.month}</option>
                                    ))}
                                </select>
                                ---
                                <YearPicker
                                    defaultValue={'year'}
                                    start={1940}                // default is 1900
                                    end={2006}                  // default is current year
                                    reverse                     // default is ASCENDING
                                    required={true}             // default is false
                                    value={yearOfBirth}     // mandatory
                                    onChange={(year) => { 
                                        setYearOfBirth(year)      // mandatory
                                    }}
                                />

                                
                            </div>
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What state are you from (origin)?
                            </SoftTypography>
                            <select style={{ width: "100%" }}
                                value={originState}
                                placeholder="Select State of Origin"
                                onChange={(e) => { setOriginState(e.target.value); getLG(e.target.value, "ORIGIN"); setOriginLG("your answer") }}
                            >
                                <option value={"your answer"}>Select your state of origin</option>
                                {localGov?.map((val, i) => (
                                    <option key={i} value={val?.state}>{val?.state}</option>
                                ))}
                            </select>
                        </SoftBox>
                        {(originState && (originState !== "your answer")) &&
                            <SoftBox mb={2}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    What LGA in {originState} are you from?
                                </SoftTypography>
                                <select style={{ width: "100%" }}
                                    value={originLG}
                                    placeholder="Select your Local "
                                    onChange={(e) => { setOriginLG(e.target.value); }}
                                >
                                    <option value={"your answer"}>Select your local government of origin</option>
                                    {lgData?.map((val, i) => (
                                        <option key={i} value={val}>{val}</option>
                                    ))}
                                </select>
                            </SoftBox>
                        }
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                In what state do you live (residence)?
                            </SoftTypography>
                            <select style={{ width: "100%" }}
                                value={residenceState}
                                placeholder="Select State of Residence"
                                onChange={(e) => { setResidenceState(e.target.value); getLG(e.target.value, "RESIDENCE"); setResidenceLG("your answer") }}
                            >
                                <option value={"your answer"}>Select your state of residence</option>
                                {localGov?.map((val, i) => (
                                    <option key={i} value={val?.state}>{val?.state}</option>
                                ))}
                            </select>
                        </SoftBox>
                        {(residenceState && (residenceState !== "your answer")) &&
                            <SoftBox mb={2}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    In what LGA in {residenceState} do you live?
                                </SoftTypography>
                                <select style={{ width: "100%" }}
                                    value={residenceLG}
                                    placeholder="Select your Local "
                                    onChange={(e) => { setResidenceLG(e.target.value); }}
                                >
                                    <option value={"your answer"}>Select your local government of residence</option>
                                    {residenceLgData?.map((val, i) => (
                                        <option key={i} value={val}>{val}</option>
                                    ))}
                                </select>
                            </SoftBox>
                        }

                    </>
                )}

                {page === 2 && (
                    <>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your most important need for credit today?
                            </SoftTypography>
                            <select style={{ width: "100%" }}
                                value={mostImportantNeed}
                                placeholder="Most important need"
                                onChange={(e) => { setMostImportantNeed(e.target.value); setSecondMostImportantNeed("your answer"); setThirdMostImportantNeed("your answer") }}
                            >
                                <option value={"your answer"} style={{ width: "100%" }}>Select your most important need for credit today</option>
                                <option value={"Buying a vehicle (car, motorbike, keke)"} style={{ width: "100%" }}>Buying a vehicle (car, motorbike, keke)</option>
                                <option value={"Buying a house"} style={{ width: "100%" }}>Buying a house</option>
                                <option value={"Renting a house"} style={{ width: "100%" }}>Renting a house</option>
                                <option value={"Buying appliances (home appliances, phone, etc)"} style={{ width: "100%" }}>Buying appliances (home appliances, phone, etc)</option>
                                <option value={"Medical bills"} style={{ width: "100%" }}>Medical bills</option>
                                {/* <option value={"Business loans (micro business)"} style={{ width: "100%" }}>Business loans (micro business)</option> */}
                                <option value={"Personal/family expenses"} style={{ width: "100%" }}>Personal/family expenses</option>
                                <option value={"Utilities (electricity, etc)"} style={{ width: "100%" }}>Utilities (electricity, etc)</option>
                                <option value={"School fees"} style={{ width: "100%" }}>School fees</option>
                                <option value={"Travels/vacation"} style={{ width: "100%" }}>Travels/vacation</option>
                                <option value={"To pay existing debts"} style={{ width: "100%" }}>To pay existing debts</option>
                                <option value={"Weddings or special events"} style={{ width: "100%" }}>Weddings or special events</option>
                                {/* <option value={"For investment"} style={{ width: "100%" }}>For investment</option> */}
                            </select>
                        </SoftBox>
                        {mostImportantNeed !== "your answer" &&
                            <SoftBox mb={2}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    After {mostImportantNeed?.toLowerCase()}, what else do you need credit for?
                                </SoftTypography>
                                <select style={{ width: "100%" }}
                                    value={secondMostImportantNeed}
                                    placeholder="Select Parastatal"
                                    onChange={(e) => { setSecondMostImportantNeed(e.target.value); setThirdMostImportantNeed("your answer") }}
                                >
                                    <option value={"your answer"} style={{ width: "100%" }}>Select what else you need credit for</option>
                                    <option value={"Buying a vehicle (car, motorbike, keke)"} style={{ width: "100%" }}>Buying a vehicle (car, motorbike, keke)</option>
                                    <option value={"Buying a house"} style={{ width: "100%" }}>Buying a house</option>
                                    <option value={"Renting a house"} style={{ width: "100%" }}>Renting a house</option>
                                    <option value={"Buying appliances (home appliances, phone, etc)"} style={{ width: "100%" }}>Buying appliances (home appliances, phone, etc)</option>
                                    <option value={"Medical bills"} style={{ width: "100%" }}>Medical bills</option>
                                    <option value={"Business loans (micro business)"} style={{ width: "100%" }}>Business loans (micro business)</option>
                                    <option value={"Personal/family expenses"} style={{ width: "100%" }}>Personal/family expenses</option>
                                    <option value={"Utilities (electricity, etc)"} style={{ width: "100%" }}>Utilities (electricity, etc)</option>
                                    <option value={"School fees"} style={{ width: "100%" }}>School fees</option>
                                    <option value={"Travels/vacation"} style={{ width: "100%" }}>Travels/vacation</option>
                                    <option value={"To pay existing debts"} style={{ width: "100%" }}>To pay existing debts</option>
                                    <option value={"Weddings or special events"} style={{ width: "100%" }}>Weddings or special events</option>
                                    {/* <option value={"For investment"} style={{ width: "100%" }}>For investment</option> */}
                                </select>
                            </SoftBox>
                        }
                        {secondMostImportantNeed !== "your answer" &&
                            <SoftBox mb={2}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    After {secondMostImportantNeed?.toLowerCase()}, what else do you need credit for?
                                </SoftTypography>
                                <select style={{ width: "100%" }}
                                    value={thirdMostImportantNeed}
                                    placeholder="Select Parastatal"
                                    onChange={(e) => setThirdMostImportantNeed(e.target.value)}
                                >
                                    <option value={"your answer"} style={{ width: "100%" }}>Select what else you need credit for</option>
                                    <option value={"Buying a vehicle (car, motorbike, keke)"} style={{ width: "100%" }}>Buying a vehicle (car, motorbike, keke)</option>
                                    <option value={"Buying a house"} style={{ width: "100%" }}>Buying a house</option>
                                    <option value={"Renting a house"} style={{ width: "100%" }}>Renting a house</option>
                                    <option value={"Buying appliances (home appliances, phone, etc)"} style={{ width: "100%" }}>Buying appliances (home appliances, phone, etc)</option>
                                    <option value={"Medical bills"} style={{ width: "100%" }}>Medical bills</option>
                                    <option value={"Business loans (micro business)"} style={{ width: "100%" }}>Business loans (micro business)</option>
                                    <option value={"Personal/family expenses"} style={{ width: "100%" }}>Personal/family expenses</option>
                                    <option value={"Utilities (electricity, etc)"} style={{ width: "100%" }}>Utilities (electricity, etc)</option>
                                    <option value={"School fees"} style={{ width: "100%" }}>School fees</option>
                                    <option value={"Travels/vacation"} style={{ width: "100%" }}>Travels/vacation</option>
                                    <option value={"To pay existing debts"} style={{ width: "100%" }}>To pay existing debts</option>
                                    <option value={"Weddings or special events"} style={{ width: "100%" }}>Weddings or special events</option>
                                    {/* <option value={"For investment"} style={{ width: "100%" }}>For investment</option> */}
                                </select>
                            </SoftBox>
                        }
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                How much money can you borrow today and pay back comfortably over 6 months?
                            </SoftTypography>
                            <SoftInput
                                type="text"
                                icon={{
                                    component: <SoftBox style={{ paddingTop: "10px" }}>₦</SoftBox>,
                                    direction: "left",
                                }}
                                inputProps={{ min: 1, max: 10000000 }}
                                value={creditAmount}
                                onChange={(e) => setCreditAmount(removeLetters(e.target.value))}
                                placeholder="50000"
                            />
                        </SoftBox>
                        <SoftBox mb={2} >
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Have you ever borrowed money from a bank, microfinance, fintech, or loan app before?
                            </SoftTypography>
                            <br />
                            <FormControl sx={{ paddingLeft: "10px" }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={everBorrowed}
                                    name="radio-buttons-group"
                                    onChange={(e) => { setEverBorrowed(e.target.value); setHighestEverBorrowed("") }}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </SoftBox>

                        {everBorrowed === "Yes" &&
                            <SoftBox mb={2}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    What is the highest amount of money you have ever borrowed?
                                </SoftTypography>
                                <SoftInput
                                    type="text"
                                    icon={{
                                        component: <SoftBox style={{ paddingTop: "10px" }}>₦</SoftBox>,
                                        direction: "left",
                                    }}
                                    value={highestEverBorrowed}
                                    onChange={(e) => setHighestEverBorrowed(removeLetters(e.target.value))}
                                    placeholder="50000"
                                />
                            </SoftBox>
                        }
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your NIN (you may dial *346# to get your NIN)
                            </SoftTypography>
                            <SoftInput
                                type="text"
                                inputProps={{ maxLength: 11 }}
                                value={ninData}
                                onChange={(e) => setNinData(removeLetters(e.target.value))}
                                placeholder="NIN"
                            />
                        </SoftBox>
                        {/* <div className="mt-3 mb-3">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Upload your Government issued ID card
                            </SoftTypography>
                            <span className="d-flex align-items-center ">
                                <i className="ri-mail-line" />
                                {!doc_image && (
                                    <div className="batch-upload-div">
                                        <div className="" style={{ padding: "10px" }}>
                                            <i className="ri-image-2-line ri-xl pt-3" />
                                            <input
                                                type="file"
                                                name=""
                                                id=""
                                                className="batch-upload-btn"
                                                value={doc_image}
                                                onChange={getBase64}
                                                accept=".jpeg,.jpg,.png"
                                                draggable={false}
                                            />
                                            <p className="p-0 m-0" style={{ fontSize: '12px' }}>
                                                ID Card Size should not be more than 1mb
                                            </p>
                                            <p className="p-0 " style={{ fontSize: '12px' }}>
                                                .jpeg, .jpg, .png
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {doc_image && (
                                    <div className="" style={{ backgroundColor: '#E7F6FC' }}>
                                        <div className="" style={{ padding: "10px" }}>
                                            <div className="col-md-8 d-flex align-items-center">
                                                <i className="ri-image-2-line ri-xl me-2" />
                                                <span>
                                                    <p className="p-0 m-0" style={{ fontSize: '15px' }}>
                                                        {doc_image?.name}
                                                    </p>
                                                    <p className="p-0 m-0" style={{ fontSize: '13px', color: '#8D8D8D' }}>
                                                        Size: {doc_image.size / 1000} KB
                                                    </p>
                                                </span>
                                            </div>
                                            <SoftBox mt={1}>
                                                <SoftButton variant="gradient" color="warning"
                                                    onClick={() => {
                                                        setDocImage(null);
                                                        setDocFormat('');
                                                    }}
                                                >
                                                    Remove
                                                </SoftButton>
                                            </SoftBox>
                                        </div>
                                    </div>
                                )}
                            </span>
                        </div> */}
                    </>
                )}

                {page === 3 && (
                    <>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What do you do for a living?
                            </SoftTypography>

                            <select style={{ width: "100%" }}
                                value={employmentType}
                                placeholder="Select Employment Type"
                                onChange={(e) => {
                                    setEmploymentType(e.target.value);
                                    (e.target.value === "Farmer" && getSubIndustry("Agriculture (Crop and Livestock)", "MAIN"));

                                    setGradeLevel('your answer');
                                    setServiceNumber('');
                                    setMinistry('your answer');
                                    setParaConfirm('');
                                    setParastatalData([]);
                                    setParastatal('your answer');
                                    setParaSearch('');
                                    setSchoolState('your answer');
                                    setSchoolName('');
                                    setIndustry('your answer');
                                    setSubIndustry('your answer');
                                    setEmploymentYears('');
                                    setSalaryRange('');
                                }}
                            >
                                <option value={"your answer"} >Select what you do for a living</option>
                                <option value={"State civil service"} >State civil service</option>
                                <option value={"Federal civil service"} >Federal civil service</option>
                                <option value={"Student"} >Student</option>
                                <option value={"Entrepreneur"} >Entrepreneur (Small business/trader/artisan)</option>
                                {/* <option value={"Executive"} >Executive</option> */}
                                <option value={"Company"} >I work in a company</option>
                                <option value={"Farmer"} >Farmer</option>
                                <option value={"Unemployed"} >Unemployed</option>
                                <option value={"Others"} >Others</option>
                            </select>
                        </SoftBox>

                        {employmentType === "State civil service" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        What is your grade level in the Civil Service?
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={gradeLevel}
                                        placeholder="Select Grade Level"
                                        onChange={(e) => setGradeLevel(e.target.value)}
                                    >
                                        <option value={"your answer"}>Select your grade level</option>
                                        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17"]?.map((val, i) => (
                                            <option key={i} value={`Level ${val}`}>{`Level ${val}`}</option>
                                        ))}
                                    </select>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        What is your IPPIS number? (Optional)
                                    </SoftTypography>
                                    <SoftInput
                                        type="text"
                                        value={serviceNumber}
                                        onChange={(e) => setServiceNumber(removeSpecialChars(e.target.value))}
                                        placeholder="What is your IPPIS number"
                                    />
                                </SoftBox>
                            </>
                        }
                        {employmentType === "Federal civil service" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        What is your grade level in the Civil Service?
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={gradeLevel}
                                        placeholder="Select Grade Level"
                                        onChange={(e) => setGradeLevel(e.target.value)}
                                    >
                                        <option value={"your answer"}>Select your grade level</option>
                                        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17"]?.map((val, i) => (
                                            <option key={i} value={`Level ${val}`}>{`Level ${val}`}</option>
                                        ))}
                                    </select>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        What is your IPPIS number? (Optional)
                                    </SoftTypography>
                                    <SoftInput
                                        type="text"
                                        value={serviceNumber}
                                        onChange={(e) => setServiceNumber(removeSpecialChars(e.target.value))}
                                        placeholder="What is your IPPIS number"
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Under what ministry or office do you work ?
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={ministry}
                                        placeholder="Select Ministry"
                                        onChange={(e) => { setMinistry(e.target.value); getParastatalData(e.target.value, "MAIN"); setParaConfirm(""); setParastatal("your answer") }}
                                    >
                                        <option value={"your answer"}>Select your ministry</option>
                                        {ministryParastatalData?.map((min, i) => (
                                            <option key={i} value={min?.ministry}>{min?.ministry}</option>
                                        ))}
                                    </select>
                                </SoftBox>


                                {(ministry && (ministry !== "your answer")) &&
                                    <SoftBox mb={2} >
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Do you work in a parastatal/agency under {ministry} ?
                                        </SoftTypography>
                                        <br />
                                        <FormControl sx={{ paddingLeft: "10px" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={paraConfirm}
                                                name="radio-buttons-group"
                                                onChange={(e) => setParaConfirm(e.target.value)}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </SoftBox>
                                }

                                {paraConfirm === "Yes" &&
                                    <SoftBox mb={2}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Parastatal
                                        </SoftTypography>
                                        <select style={{ width: "100%" }}
                                            value={parastatal}
                                            placeholder="Select Parastatal"
                                            onChange={(e) => setParastatal(e.target.value)}
                                        >
                                            <option value={"your answer"}>Select parastatal</option>
                                            {parastatalData?.map((val, i) => (
                                                <option key={i} value={val}>{val}</option>
                                            ))}
                                            <option value={"all"}>I cannot find my parastatal</option>
                                        </select>
                                        {/* <Select sx={{ width: "100%" }}
                                            value={parastatal}
                                            placeholder="Select Parastatal"
                                            onChange={(e) => setParastatal(e.target.value)}
                                        >
                                            <MenuItem value={"your answer"} sx={{ width: "100%" }}>Select parastatal</MenuItem>
                                            {parastatalData?.map((val, i) => (
                                                <MenuItem key={i} value={val} sx={{ width: "100%" }}>{val}</MenuItem>
                                            ))}
                                            <MenuItem value={"all"} sx={{ width: "100%" }}>I cannot find my parastatal</MenuItem>
                                        </Select> */}
                                        {parastatal === "all" &&
                                            <Autocomplete
                                                mt={1}
                                                disablePortal
                                                sx={{ width: "100%" }}
                                                id="paraSearch"
                                                value={paraSearch}
                                                onChange={e => setParaSearch(e.target.innerText)}
                                                options={allParastatalData?.sort()}
                                                renderInput={(params) =>
                                                    <SoftBox ref={params.InputProps.ref} mt={1} >
                                                        <SoftInput
                                                            type="text"
                                                            placeholder="Search for parastatal"
                                                            {...params.inputProps}
                                                        />
                                                    </SoftBox>
                                                }
                                            />
                                        }
                                    </SoftBox>
                                }

                            </>
                        }
                        {employmentType === "Student" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        State of School(select)
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={schoolState}
                                        placeholder="Select state of school"
                                        onChange={(e) => setSchoolState(e.target.value)}
                                    >
                                        <option value={"your answer"}>Select your school state</option>
                                        {localGov?.map((val, i) => (
                                            <option key={i} value={val?.state}>{val?.state}</option>
                                        ))}
                                    </select>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Name of School
                                    </SoftTypography>
                                    <SoftInput
                                        type="text"
                                        value={schoolName}
                                        onChange={(e) => setSchoolName(e.target.value)}
                                        placeholder="Input school"
                                    />
                                </SoftBox>
                            </>
                        }
                        {employmentType === "Entrepreneur" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Industry
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={industry}
                                        placeholder="Select your industry"
                                        onChange={(e) => { setIndustry(e.target.value); getSubIndustry(e.target.value, "MAIN"); setSubIndustry("your answer") }}
                                    >
                                        <option value={"your answer"}>Select your industry</option>
                                        {subIndustryData?.map((val, i) => (
                                            <option key={i} value={val?.industry}>{val?.industry}</option>
                                        ))}
                                    </select>
                                </SoftBox>
                                {(industry && (industry !== "your answer")) &&
                                    <SoftBox mb={2}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Sub industry
                                        </SoftTypography>
                                        <select style={{ width: "100%" }}
                                            value={subIndustry}
                                            placeholder="Select your industry"
                                            onChange={(e) => { setSubIndustry(e.target.value) }}
                                        >
                                            <option value={"your answer"}>Select your sub industry</option>
                                            {subIndustryDataData?.map((val, i) => (
                                                <option key={i} value={val}>{val}</option>
                                            ))}
                                        </select>
                                    </SoftBox>
                                }
                            </>
                        }
                        {employmentType === "Executive" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Industry
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={industry}
                                        placeholder="Select your industry"
                                        onChange={(e) => { setIndustry(e.target.value); getSubIndustry(e.target.value, "MAIN"); setSubIndustry("your answer") }}
                                    >
                                        <option value={"your answer"}>Select your industry</option>
                                        {subIndustryData?.map((val, i) => (
                                            <option key={i} value={val?.industry}>{val?.industry}</option>
                                        ))}
                                    </select>
                                </SoftBox>
                                {(industry && (industry !== "your answer")) &&
                                    <SoftBox mb={2}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Sub industry
                                        </SoftTypography>
                                        <select style={{ width: "100%" }}
                                            value={subIndustry}
                                            placeholder="Select your industry"
                                            onChange={(e) => { setSubIndustry(e.target.value) }}
                                        >
                                            <option value={"your answer"}>Select your sub industry</option>
                                            {subIndustryDataData?.map((val, i) => (
                                                <option key={i} value={val} >{val}</option>
                                            ))}
                                        </select>
                                    </SoftBox>
                                }
                            </>
                        }
                        {employmentType === "Company" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Industry
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={industry}
                                        placeholder="Select your industry"
                                        onChange={(e) => { setIndustry(e.target.value); getSubIndustry(e.target.value, "MAIN"); setSubIndustry("your answer") }}
                                    >
                                        <option value={"your answer"} >Select your industry</option>
                                        {subIndustryData?.map((val, i) => (
                                            <option key={i} value={val?.industry} >{val?.industry}</option>
                                        ))}
                                    </select>
                                </SoftBox>
                                {(industry && (industry !== "your answer")) &&
                                    <SoftBox mb={2}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Sub industry
                                        </SoftTypography>
                                        <select style={{ width: "100%" }}
                                            value={subIndustry}
                                            placeholder="Select your industry"
                                            onChange={(e) => { setSubIndustry(e.target.value) }}
                                        >
                                            <option value={"your answer"} style={{ width: "100%" }}>Select your sub industry</option>
                                            {subIndustryDataData?.map((val, i) => (
                                                <option key={i} value={val}>{val}</option>
                                            ))}
                                        </select>
                                    </SoftBox>
                                }
                            </>
                        }
                        {employmentType === "Farmer" &&
                            <>
                                {/* <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Industry
                                    </SoftTypography>
                                    <Select sx={{ width: "100%" }}
                                        value={industry}
                                        placeholder="Select your industry"
                                        onChange={(e) => { setIndustry(e.target.value); getSubIndustry(e.target.value, "MAIN"); setSubIndustry("your answer") }}
                                    >
                                        <MenuItem value={"your answer"} sx={{ width: "100%" }}>Select your industry</MenuItem>
                                        {subIndustryData?.map((val, i) => (
                                            <MenuItem key={i} value={val?.industry} fullWidth>{val?.industry}</MenuItem>
                                        ))}
                                    </Select>
                                </SoftBox> */}
                                {/* {(industry && (industry !== "your answer")) && */}
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Sub industry
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={subIndustry}
                                        placeholder="Select your industry"
                                        onChange={(e) => { setSubIndustry(e.target.value) }}
                                    >
                                        <option value={"your answer"}>Select your sub industry</option>
                                        {subIndustryDataData?.map((val, i) => (
                                            <option key={i} value={val}>{val}</option>
                                        ))}
                                    </select>
                                </SoftBox>
                                {/* } */}
                            </>
                        }
                        {/* {employmentType === "Others" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Others Specify
                                    </SoftTypography>
                                    <SoftInput
                                        type="text"
                                        value={otherData}
                                        onChange={(e) => setOtherData(e.target.value)}
                                        placeholder="Input others"
                                    />
                                </SoftBox>
                            </>
                        } */}

                        {employmentType !== "Student" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        For how many years have you had your current employment?
                                    </SoftTypography>
                                    <SoftInput
                                        type="text"
                                        value={employmentYears}
                                        inputProps={{ maxLength: 2 }}
                                        onChange={(e) => setEmploymentYears(removeLetters(e.target.value))}
                                        placeholder="For how many years have you had your current employment?"
                                    />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" >
                                        How much do you earn per month from your MAIN employment (the minimum salary or income that enters your bank account monthly)
                                    </SoftTypography>
                                    <SoftInput
                                        type="text"
                                        icon={{
                                            component: <SoftBox style={{ paddingTop: "10px" }}>₦</SoftBox>,
                                            direction: "left",
                                        }}
                                        inputProps={{ min: 1, max: 10000000 }}
                                        value={salaryRange}
                                        onChange={(e) => setSalaryRange(removeLetters(e.target.value))}
                                        placeholder="Enter amount"
                                    />
                                </SoftBox>
                            </>
                        }
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                In what bank do you receive this income? (this is where you would receive credit if you qualify)
                            </SoftTypography>
                            <select style={{ width: "100%" }}
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                                placeholder="Bank Name"
                            >
                                <option value={"your answer"}>Select your bank</option>
                                {nigeriaBanks?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((val, i) => (
                                    <option key={i} value={val?.name}>{val?.name}</option>
                                ))}
                            </select>
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                What is your bank account number?
                            </SoftTypography>
                            <SoftInput
                                type="text"
                                value={accountNumber}
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => setAccountNumber(removeLetters(e.target.value))}
                                placeholder="Account Number"
                            />
                        </SoftBox>
                        {/* <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                BVN
                            </SoftTypography>
                            <SoftInput
                                type="number"
                                value={bvnData}
                                onChange={(e) => setBvnData(e.target.value)}
                                placeholder="BVN"
                            />
                        </SoftBox> */}
                    </>
                )}

                {page === 4 && (
                    <>
                        <SoftBox mb={2} >
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Do you have a SECONDARY employment/side hustle?
                            </SoftTypography>
                            <br />
                            <FormControl sx={{ paddingLeft: "10px" }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={secEmployment}
                                    name="radio-buttons-group"
                                    onChange={(e) => {
                                        setSecEmployment(e.target.value); setSecEmploymentType('your answer');
                                        setSecGradeLevel('your answer'); setSecServiceNumber('');
                                        setSecMinistry('your answer'); setSecParaConfirm('');
                                        setSecParastatalData([]); setSecParastatal('your answer');
                                        setSecParaSearch(''); setSecSchoolState('your answer');
                                        setSecSchoolName(''); setSecIndustry('your answer');
                                        setSecSubIndustryDataData([]); setSecSubIndustry('your answer');
                                        setSecEmploymentYears('');
                                        setSecSalaryRange('');
                                    }}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </SoftBox>

                        {secEmployment === "Yes" &&
                            <>
                                <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        What is your secondary employment/side hustle?
                                    </SoftTypography>
                                    <select style={{ width: "100%" }}
                                        value={secEmploymentType}
                                        placeholder="Select Employment Type"
                                        onChange={(e) => {
                                            setSecEmploymentType(e.target.value);
                                            (e.target.value === "Farmer" && getSubIndustry("Agriculture (Crop and Livestock)", "SECONDARY"))
                                            setSecGradeLevel('your answer');
                                            setSecServiceNumber('');
                                            setSecMinistry('your answer');
                                            setSecParaConfirm('');
                                            setSecParastatalData([]);
                                            setSecParastatal('your answer');
                                            setSecParaSearch('');
                                            setSecSchoolState('your answer');
                                            setSecSchoolName('');
                                            setSecIndustry('your answer');
                                            setSecSubIndustry('your answer');
                                        }}
                                    >
                                        <option value={"your answer"} >Select what you do for a living</option>
                                        <option value={"State civil service"} >State civil service</option>
                                        <option value={"Federal civil service"} >Federal civil service</option>
                                        <option value={"Student"} >Student</option>
                                        <option value={"Entrepreneur"} >Entrepreneur (Small business/trader/artisan)</option>
                                        {/* <option value={"Executive"} >Executive</option> */}
                                        <option value={"Company"} >I work in a company</option>
                                        <option value={"Farmer"} >Farmer</option>
                                        <option value={"Unemployed"} >Unemployed</option>
                                        <option value={"Others"} >Others</option>
                                    </select>
                                </SoftBox>

                                {/* <SoftBox mb={2}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        What is your secondary employment/side hustle?
                                    </SoftTypography>
                                    <Select sx={{ width: "100%" }}
                                        value={secEmploymentType}
                                        placeholder="Select Employment Type"
                                        onChange={(e) => { setSecEmploymentType(e.target.value); (e.target.value === "Farmer" && getSubIndustry("Agriculture (Crop and Livestock)", "SECONDARY")) }}
                                    >
                                        <MenuItem value={"State civil service"} sx={{ width: "100%" }}>State civil service</MenuItem>
                                        <MenuItem value={"Federal civil service"} sx={{ width: "100%" }}>Federal civil service</MenuItem>
                                        <MenuItem value={"Student"} sx={{ width: "100%" }}>Student</MenuItem>
                                        <MenuItem value={"Entrepreneur"} sx={{ width: "100%" }}>Entrepreneur</MenuItem>
                                        <MenuItem value={"Executive"} sx={{ width: "100%" }}>Executive</MenuItem>
                                        <MenuItem value={"Company"} sx={{ width: "100%" }}>I work in a company</MenuItem>
                                        <MenuItem value={"Farmer"} sx={{ width: "100%" }}>Farmer</MenuItem>
                                        <MenuItem value={"Unemployed"} sx={{ width: "100%" }}>Unemployed</MenuItem>
                                        <MenuItem value={"Others"} sx={{ width: "100%" }}>Others</MenuItem>
                                    </Select>
                                </SoftBox> */}

                                {secEmploymentType === "State civil service" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                What is your grade level in the Civil Service?
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secGradeLevel}
                                                placeholder="Select Grade Level"
                                                onChange={(e) => setSecGradeLevel(e.target.value)}
                                            >
                                                <option value={"your answer"}>Select your grade level</option>
                                                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17"]?.map((val, i) => (
                                                    <option key={i} value={`Level ${val}`}>{`Level ${val}`}</option>
                                                ))}
                                            </select>
                                        </SoftBox>

                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                What is your IPPIS number? (Optional)
                                            </SoftTypography>
                                            <SoftInput
                                                type="text"
                                                value={secServiceNumber}
                                                onChange={(e) => setSecServiceNumber(removeSpecialChars(e.target.value))}
                                                placeholder="What is your IPPIS number"
                                            />
                                        </SoftBox>
                                    </>
                                }
                                {secEmploymentType === "Federal civil service" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                What is your grade level in the Civil Service?
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secGradeLevel}
                                                placeholder="Select Grade Level"
                                                onChange={(e) => setSecGradeLevel(e.target.value)}
                                            >
                                                <option value={"your answer"}>Select your grade level</option>
                                                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17"]?.map((val, i) => (
                                                    <option key={i} value={`Level ${val}`}>{`Level ${val}`}</option>
                                                ))}
                                            </select>
                                        </SoftBox>

                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                What is your IPPIS number? (Optional)
                                            </SoftTypography>
                                            <SoftInput
                                                type="text"
                                                value={secServiceNumber}
                                                onChange={(e) => setSecServiceNumber(removeSpecialChars(e.target.value))}
                                                placeholder="What is your IPPIS number"
                                            />
                                        </SoftBox>

                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Under what ministry or office do you work?
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secMinistry}
                                                placeholder="Select Ministry"
                                                onChange={(e) => { setSecMinistry(e.target.value); getParastatalData(e.target.value, "SECONDARY"); setSecParaConfirm(""); setSecParastatal("your answer") }}
                                            >
                                                <option value={"your answer"}>Select your ministry</option>
                                                {ministryParastatalData?.map((min, i) => (
                                                    <option key={i} value={min?.ministry}>{min?.ministry}</option>
                                                ))}
                                            </select>
                                        </SoftBox>


                                        {(secMinistry && (secMinistry !== "your answer")) &&
                                            <SoftBox mb={2} >
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Do you work in a parastatal/agency under {secMinistry}?
                                                </SoftTypography>
                                                <br />
                                                <FormControl sx={{ paddingLeft: "10px" }}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        value={secParaConfirm}
                                                        name="radio-buttons-group"
                                                        onChange={(e) => setSecParaConfirm(e.target.value)}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </SoftBox>
                                        }

                                        {secParaConfirm === "Yes" &&
                                            <SoftBox mb={2}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Parastatal
                                                </SoftTypography>
                                                <select style={{ width: "100%" }}
                                                    value={secParastatal}
                                                    placeholder="Select Parastatal"
                                                    onChange={(e) => setSecParastatal(e.target.value)}
                                                >
                                                    <option value={"your answer"}>Select your parastatal</option>
                                                    {secParastatalData?.map((val, i) => (
                                                        <option key={i} value={val}>{val}</option>
                                                    ))}
                                                    <option value={"all"}>I cannot find my parastatal</option>
                                                </select>
                                            </SoftBox>
                                        }

                                        {secParastatal === "all" &&
                                            <Autocomplete
                                                mt={1}
                                                disablePortal
                                                sx={{ width: "100%" }}
                                                id="secParaSearch"
                                                value={secParaSearch}
                                                onChange={e => setSecParaSearch(e.target.innerText)}
                                                options={allParastatalData?.sort()}
                                                renderInput={(params) =>
                                                    <SoftBox ref={params.InputProps.ref} mt={1} >
                                                        <SoftInput
                                                            type="text"
                                                            placeholder="Search for parastatal"
                                                            {...params.inputProps}
                                                        />
                                                    </SoftBox>
                                                }
                                            />
                                        }
                                    </>
                                }
                                {secEmploymentType === "Student" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                State of School (Select)
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secSchoolState}
                                                placeholder="Select state of school"
                                                onChange={(e) => setSecSchoolState(e.target.value)}
                                            >
                                                <option value={"your answer"}>Select your school state</option>
                                                {localGov?.map((val, i) => (
                                                    <option key={i} value={val?.state}>{val?.state}</option>
                                                ))}
                                            </select>
                                        </SoftBox>

                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Name of School
                                            </SoftTypography>
                                            <SoftInput
                                                type="text"
                                                value={secSchoolName}
                                                onChange={(e) => setSecSchoolName(e.target.value)}
                                                placeholder="Input school"
                                            />
                                        </SoftBox>
                                    </>
                                }
                                {secEmploymentType === "Entrepreneur" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Industry
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secIndustry}
                                                placeholder="Select your industry"
                                                onChange={(e) => { setSecIndustry(e.target.value); getSubIndustry(e.target.value, "SECONDARY"); setSecSubIndustry("your answer") }}
                                            >
                                                <option value={"your answer"}>Select your industry</option>
                                                {subIndustryData?.map((val, i) => (
                                                    <option key={i} value={val?.industry}>{val?.industry}</option>
                                                ))}
                                            </select>
                                        </SoftBox>
                                        {(secIndustry && (secIndustry !== "your answer")) &&
                                            <SoftBox mb={2}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Sub industry
                                                </SoftTypography>
                                                <select style={{ width: "100%" }}
                                                    value={secSubIndustry}
                                                    placeholder="Select your industry"
                                                    onChange={(e) => { setSecSubIndustry(e.target.value) }}
                                                >
                                                    <option value={"your answer"}>Select your sub industry</option>
                                                    {secSubIndustryDataData?.map((val, i) => (
                                                        <option key={i} value={val}>{val}</option>
                                                    ))}
                                                </select>
                                            </SoftBox>
                                        }
                                    </>
                                }
                                {secEmploymentType === "Executive" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Industry
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secIndustry}
                                                placeholder="Select your industry"
                                                onChange={(e) => { setSecIndustry(e.target.value); getSubIndustry(e.target.value, "SECONDARY"); setSecSubIndustry("your answer") }}
                                            >
                                                <option value={"your answer"}>Select your industry</option>
                                                {subIndustryData?.map((val, i) => (
                                                    <option key={i} value={val?.industry}>{val?.industry}</option>
                                                ))}
                                            </select>
                                        </SoftBox>
                                        {(secIndustry && (secIndustry !== "your answer")) &&
                                            <SoftBox mb={2}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Sub industry
                                                </SoftTypography>
                                                <select style={{ width: "100%" }}
                                                    value={secSubIndustry}
                                                    placeholder="Select your industry"
                                                    onChange={(e) => { setSecSubIndustry(e.target.value) }}
                                                >
                                                    <option value={"your answer"}>Select your sub industry</option>
                                                    {secSubIndustryDataData?.map((val, i) => (
                                                        <option key={i} value={val}>{val}</option>
                                                    ))}
                                                </select>
                                            </SoftBox>
                                        }
                                    </>
                                }
                                {secEmploymentType === "Company" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Industry
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secIndustry}
                                                placeholder="Select your industry"
                                                onChange={(e) => { setSecIndustry(e.target.value); getSubIndustry(e.target.value, "SECONDARY"); setSecSubIndustry("your answer") }}
                                            >
                                                <option value={"your answer"}>Select your industry</option>
                                                {subIndustryData?.map((val, i) => (
                                                    <option key={i} value={val?.industry}>{val?.industry}</option>
                                                ))}
                                            </select>
                                        </SoftBox>
                                        {(secIndustry && (secIndustry !== "your answer")) &&
                                            <SoftBox mb={2}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Sub industry
                                                </SoftTypography>
                                                <select style={{ width: "100%" }}
                                                    value={secSubIndustry}
                                                    placeholder="Select your industry"
                                                    onChange={(e) => { setSecSubIndustry(e.target.value) }}
                                                >
                                                    <option value={"your answer"}>Select your sub industry</option>
                                                    {secSubIndustryDataData?.map((val, i) => (
                                                        <option key={i} value={val}>{val}</option>
                                                    ))}
                                                </select>
                                            </SoftBox>
                                        }
                                    </>
                                }
                                {secEmploymentType === "Farmer" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Sub industry
                                            </SoftTypography>
                                            <select style={{ width: "100%" }}
                                                value={secSubIndustry}
                                                placeholder="Select your industry"
                                                onChange={(e) => { setSecSubIndustry(e.target.value) }}
                                            >
                                                <option value={"your answer"}>Select your sub industry</option>
                                                {secSubIndustryDataData?.map((val, i) => (
                                                    <option key={i} value={val}>{val}</option>
                                                ))}
                                            </select>
                                        </SoftBox>
                                    </>
                                }
                                {/* {secEmploymentType === "Others" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Others Specify
                                            </SoftTypography>
                                            <SoftInput
                                                type="text"
                                                value={secOtherData}
                                                onChange={(e) => setSecOtherData(e.target.value)}
                                                placeholder="Input others"
                                            />
                                        </SoftBox>
                                    </>
                                } */}


                                {secEmploymentType !== "Student" &&
                                    <>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                For how many years have you had your secondary employment?
                                            </SoftTypography>
                                            <SoftInput
                                                type="text"
                                                value={secEmploymentYears}
                                                inputProps={{ maxLength: 2 }}
                                                onChange={(e) => setSecEmploymentYears(removeLetters(e.target.value))}
                                                placeholder="Number of Years in Employment"
                                            />
                                        </SoftBox>
                                        <SoftBox mb={2}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                How much do you earn per month from your SECONDARY employment
                                                (the minimum salary or income that enters your bank account monthly)?
                                            </SoftTypography>
                                            <SoftInput
                                                type="text"
                                                icon={{
                                                    component: <SoftBox style={{ paddingTop: "10px" }}>₦</SoftBox>,
                                                    direction: "left",
                                                }}
                                                inputProps={{ min: 1, max: 10000000 }}
                                                value={secSalaryRange}
                                                onChange={(e) => setSecSalaryRange(removeLetters(e.target.value))}
                                                placeholder="Enter amount"
                                            />
                                        </SoftBox>
                                    </>
                                }
                            </>
                        }
                        <SoftBox mb={2} mt={3}>
                            <SoftTypography component="label" variant="h6" fontWeight="regular">
                                By submitting this application, you consent to credit check and the use of my data for program needs.
                            </SoftTypography>
                            <br />
                            <FormControl ms={3}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={consent}
                                    name="radio-buttons-group"
                                    onChange={(e) => setConsent(e.target.value)}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes, I agree" />
                                </RadioGroup>
                            </FormControl>

                        </SoftBox>

                    </>
                )}

                {page === 5 && (
                    <>
                        <FaceCaptureComp proceedFromFaceCapture={initiateReg} goToPrevious={prev} initiateLoading={loading} />
                    </>
                )}

                {page < 5 &&
                    <SoftBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <SoftButton variant="outlined" color="info" onClick={prev}
                            disabled={page === 1} sx={{ width: "45%" }}
                        >
                            Previous
                        </SoftButton>
                        <SoftButton variant="contained" color="info" onClick={next}
                            sx={{ width: "45%" }}
                        >
                            {page < 5 ? "Proceed" : "Submit"}
                        </SoftButton>
                    </SoftBox>
                }


                <SoftBox display={{ xs: "block", xl: "none" }}>
                    <SoftTypography my={5} variant="body2" fontWeight="regular" color="text">
                        Are you having an issue?,
                        contact us <a style={{ cursor: "pointer", borderBottom: "1px solid #08ddb8", color: "#08ddb8" }} href="https://www.credicorp.ng/contact" target="_blank" rel="noreferrer">here </a>
                    </SoftTypography>
                </SoftBox>






                {/* <SoftBox mb={2}>
                    <SoftBox mb={2} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Email
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput type="email" placeholder="Email" />
                </SoftBox>
                <SoftBox mb={2}>
                    <SoftBox mb={2} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Password
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput type="password" placeholder="Password" />
                </SoftBox> */}
                {/* <SoftBox display="flex" alignItems="center">
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={handleSetRememberMe}
                        sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                        &nbsp;&nbsp;Remember me
                    </SoftTypography>
                </SoftBox> */}
                {/* <SoftBox mt={4} mb={1}>
                    <SoftButton variant="gradient" color="info" fullWidth>
                        sign in
                    </SoftButton>
                </SoftBox> */}
                {/* <SoftBox mt={3} textAlign="center">
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                        Don&apos;t have an account?{" "}
                        <SoftTypography
                            component={Link}
                            to="/authentication/sign-up"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                        >
                            Sign up
                        </SoftTypography>
                    </SoftTypography>
                </SoftBox> */}
            </SoftBox>
        </CoverLayout>
    );
}

// Apply.propTypes = {
//     value: PropTypes.string,
// };
export default Apply;
