import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { NavLink, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Card, CircularProgress } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import CoverLayout from '../components/CoverLayout';
import ccImg from "assets/images/curved-images/cc-img.png";
import logo from "assets/images/app-logo.jpeg"
import SoftAlert from "components/SoftAlert";
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import successImg from '../../../assets/images/succes-img.png'

const SuccessPage = () => {
	return (

		<CoverLayout
			title=""
			description=""
			image={ccImg}
			logo={logo}
		>
			<SoftBox style={{textAlign:"left"}} >
                <img src={successImg} width="120px"/>

                <SoftBox mt={3} mb={1}>
                <SoftTypography variant="h1" fontWeight="bold">
                    Thank you for expressing interest
                </SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                <SoftTypography variant="body2" fontWeight="regular">
                    Your information will be reviewed as the credit scheme rolls out. Eligible 
                    candidates will be served by their financial institutions. Please exercise 
                    patience if not successful in the first phase, as there are many phases.
                </SoftTypography>
                </SoftBox>

			</SoftBox>
		</CoverLayout>
	);
};
export default SuccessPage;
