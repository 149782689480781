// export let nigeriaBanks = [
//     {
//      "id": 302,
//      "active": true,
//      "code": "120001",
//      "country": "Nigeria",
//      "createdAt": "2022-05-31T06:50:27.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "9mobile 9Payment Service Bank",
//      "pay_with_bank": false,
//      "slug": "9mobile-9payment-service-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-06-23T09:33:55.000Z",
//      "value": "120001"
//     },
//     {
//      "id": 174,
//      "active": true,
//      "code": "801",
//      "country": "Nigeria",
//      "createdAt": "2020-12-07T16:19:09.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Abbey Mortgage Bank",
//      "pay_with_bank": false,
//      "slug": "abbey-mortgage-bank",
//      "type": "nuban",
//      "updatedAt": "2020-12-07T16:19:19.000Z",
//      "value": "801"
//     },
//     {
//      "id": 188,
//      "active": true,
//      "code": "51204",
//      "country": "Nigeria",
//      "createdAt": "2021-10-13T20:35:17.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Above Only MFB",
//      "pay_with_bank": false,
//      "slug": "above-only-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-10-13T20:35:17.000Z",
//      "value": "188"
//     },
//     {
//      "id": 627,
//      "active": true,
//      "code": "51312",
//      "country": "Nigeria",
//      "createdAt": "2022-08-31T08:26:20.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Abulesoro MFB",
//      "pay_with_bank": false,
//      "slug": "abulesoro-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-08-31T08:26:20.000Z",
//      "value": "51312"
//     },
//     {
//      "id": 1,
//      "active": true,
//      "code": "044",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Access Bank",
//      "pay_with_bank": false,
//      "slug": "access-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T08:06:44.000Z",
//      "value": "044"
//     },
//     {
//      "id": 3,
//      "active": true,
//      "code": "063",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Access Bank (Diamond)",
//      "pay_with_bank": false,
//      "slug": "access-bank-diamond",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T08:06:48.000Z",
//      "value": "063"
//     },
//     {
//      "id": 495,
//      "active": true,
//      "code": "602",
//      "country": "Nigeria",
//      "createdAt": "2022-07-28T14:22:56.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Accion Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "accion-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-19T07:48:37.000Z",
//      "value": "602"
//     },
//     {
//      "id": 676,
//      "active": true,
//      "code": "50036",
//      "country": "Nigeria",
//      "createdAt": "2022-11-14T13:35:42.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ahmadu Bello University Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "ahmadu-bello-university-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-11-14T13:35:42.000Z",
//      "value": "50036"
//     },
//     {
//      "id": 300,
//      "active": true,
//      "code": "120004",
//      "country": "Nigeria",
//      "createdAt": "2022-05-30T14:03:00.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "Airtel Smartcash PSB",
//      "pay_with_bank": false,
//      "slug": "airtel-smartcash-psb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-05-31T06:58:22.000Z",
//      "value": "120004"
//     },
//     {
//      "id": 27,
//      "active": true,
//      "code": "035A",
//      "country": "Nigeria",
//      "createdAt": "2017-11-15T12:21:31.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "ALAT by WEMA",
//      "pay_with_bank": false,
//      "slug": "alat-by-wema",
//      "type": "nuban",
//      "updatedAt": "2022-05-31T15:54:34.000Z",
//      "value": "035A"
//     },
//     {
//      "id": 179,
//      "active": true,
//      "code": "50926",
//      "country": "Nigeria",
//      "createdAt": "2021-07-07T13:45:57.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Amju Unique MFB",
//      "pay_with_bank": false,
//      "slug": "amju-unique-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-07-07T13:45:57.000Z",
//      "value": "50926"
//     },
//     {
//      "id": 689,
//      "active": true,
//      "code": "51341",
//      "country": "Nigeria",
//      "createdAt": "2023-03-23T11:11:40.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "AMPERSAND MICROFINANCE BANK",
//      "pay_with_bank": false,
//      "slug": "ampersand-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2023-03-23T11:11:40.000Z",
//      "value": "51341"
//     },
//     {
//      "id": 614,
//      "active": true,
//      "code": "50083",
//      "country": "Nigeria",
//      "createdAt": "2022-08-10T09:48:24.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Aramoko MFB",
//      "pay_with_bank": false,
//      "slug": "aramoko-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-08-10T09:48:24.000Z",
//      "value": "50083"
//     },
//     {
//      "id": 63,
//      "active": true,
//      "code": "401",
//      "country": "Nigeria",
//      "createdAt": "2018-09-23T05:52:38.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "ASO Savings and Loans",
//      "pay_with_bank": false,
//      "slug": "asosavings",
//      "type": "nuban",
//      "updatedAt": "2019-01-30T09:38:57.000Z",
//      "value": "401"
//     },
//     {
//      "id": 297,
//      "active": true,
//      "code": "MFB50094",
//      "country": "Nigeria",
//      "createdAt": "2022-05-25T10:46:17.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Astrapolaris MFB LTD",
//      "pay_with_bank": false,
//      "slug": "astrapolaris-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-05-25T10:46:17.000Z",
//      "value": "MFB50094"
//     },
//     {
//      "id": 181,
//      "active": true,
//      "code": "51229",
//      "country": "Nigeria",
//      "createdAt": "2021-07-12T14:41:18.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Bainescredit MFB",
//      "pay_with_bank": false,
//      "slug": "bainescredit-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-07-12T14:41:18.000Z",
//      "value": "51229"
//     },
//     {
//      "id": 686,
//      "active": true,
//      "code": "50117",
//      "country": "Nigeria",
//      "createdAt": "2023-03-06T15:39:58.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "Banc Corp Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "banc-corp-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2023-03-06T15:39:58.000Z",
//      "value": "50117"
//     },
//     {
//      "id": 108,
//      "active": true,
//      "code": "50931",
//      "country": "Nigeria",
//      "createdAt": "2020-02-11T15:38:57.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Bowen Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "bowen-microfinance-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-11T15:38:57.000Z",
//      "value": "50931"
//     },
//     {
//      "id": 82,
//      "active": true,
//      "code": "565",
//      "country": "Nigeria",
//      "createdAt": "2020-06-16T08:15:31.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Carbon",
//      "pay_with_bank": false,
//      "slug": "carbon",
//      "type": "nuban",
//      "updatedAt": "2021-08-05T15:25:01.000Z",
//      "value": "565"
//     },
//     {
//      "id": 692,
//      "active": true,
//      "code": "865",
//      "country": "Nigeria",
//      "createdAt": "2023-04-05T14:29:19.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "CASHCONNECT MFB",
//      "pay_with_bank": false,
//      "slug": "cashconnect-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2023-04-05T14:29:19.000Z",
//      "value": "865"
//     },
//     {
//      "id": 74,
//      "active": true,
//      "code": "50823",
//      "country": "Nigeria",
//      "createdAt": "2020-03-23T15:06:13.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "CEMCS Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "cemcs-microfinance-bank",
//      "type": "nuban",
//      "updatedAt": "2020-03-23T15:06:28.000Z",
//      "value": "50823"
//     },
//     {
//      "id": 284,
//      "active": true,
//      "code": "50171",
//      "country": "Nigeria",
//      "createdAt": "2022-02-10T13:28:38.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "Chanelle Microfinance Bank Limited",
//      "pay_with_bank": false,
//      "slug": "chanelle-microfinance-bank-limited-ng",
//      "type": "nuban",
//      "updatedAt": "2022-02-10T13:28:38.000Z",
//      "value": "50171"
//     },
//     {
//      "id": 2,
//      "active": true,
//      "code": "023",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Citibank Nigeria",
//      "pay_with_bank": false,
//      "slug": "citibank-nigeria",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:24:02.000Z",
//      "value": "023"
//     },
//     {
//      "id": 691,
//      "active": true,
//      "code": "50910",
//      "country": "Nigeria",
//      "createdAt": "2023-03-28T06:18:02.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Consumer Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "consumer-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2023-03-28T06:18:02.000Z",
//      "value": "50910"
//     },
//     {
//      "id": 283,
//      "active": true,
//      "code": "50204",
//      "country": "Nigeria",
//      "createdAt": "2022-02-09T14:33:06.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Corestep MFB",
//      "pay_with_bank": false,
//      "slug": "corestep-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-02-09T14:33:06.000Z",
//      "value": "50204"
//     },
//     {
//      "id": 173,
//      "active": true,
//      "code": "060001",
//      "country": "Nigeria",
//      "createdAt": "2020-11-24T10:25:07.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Coronation Merchant Bank",
//      "pay_with_bank": false,
//      "slug": "coronation-merchant-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2023-04-14T14:55:14.000Z",
//      "value": "060001"
//     },
//     {
//      "id": 366,
//      "active": true,
//      "code": "51297",
//      "country": "Nigeria",
//      "createdAt": "2022-07-18T12:39:03.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Crescent MFB",
//      "pay_with_bank": false,
//      "slug": "crescent-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-07-18T12:39:03.000Z",
//      "value": "51297"
//     },
//     {
//      "id": 637,
//      "active": true,
//      "code": "50162",
//      "country": "Nigeria",
//      "createdAt": "2022-11-03T14:39:09.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Dot Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "dot-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-11-03T14:39:09.000Z",
//      "value": "50162"
//     },
//     {
//      "id": 4,
//      "active": true,
//      "code": "050",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ecobank Nigeria",
//      "pay_with_bank": false,
//      "slug": "ecobank-nigeria",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:23:53.000Z",
//      "value": "050"
//     },
//     {
//      "id": 628,
//      "active": true,
//      "code": "50263",
//      "country": "Nigeria",
//      "createdAt": "2022-08-31T08:26:39.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ekimogun MFB",
//      "pay_with_bank": false,
//      "slug": "ekimogun-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-08-31T08:26:39.000Z",
//      "value": "50263"
//     },
//     {
//      "id": 64,
//      "active": true,
//      "code": "098",
//      "country": "Nigeria",
//      "createdAt": "2018-09-23T05:55:06.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ekondo Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "ekondo-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-21T15:09:51.000Z",
//      "value": "098"
//     },
//     {
//      "id": 167,
//      "active": true,
//      "code": "50126",
//      "country": "Nigeria",
//      "createdAt": "2020-09-07T13:52:22.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Eyowo",
//      "pay_with_bank": false,
//      "slug": "eyowo",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:03:21.000Z",
//      "value": "50126"
//     },
//     {
//      "id": 677,
//      "active": true,
//      "code": "51318",
//      "country": "Nigeria",
//      "createdAt": "2022-11-15T12:33:47.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Fairmoney Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "fairmoney-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-11-15T12:37:44.000Z",
//      "value": "51318"
//     },
//     {
//      "id": 6,
//      "active": true,
//      "code": "070",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Fidelity Bank",
//      "pay_with_bank": false,
//      "slug": "fidelity-bank",
//      "type": "nuban",
//      "updatedAt": "2021-08-27T09:15:29.000Z",
//      "value": "070"
//     },
//     {
//      "id": 177,
//      "active": true,
//      "code": "51314",
//      "country": "Nigeria",
//      "createdAt": "2021-06-01T15:33:26.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Firmus MFB",
//      "pay_with_bank": false,
//      "slug": "firmus-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-06-01T15:33:26.000Z",
//      "value": "51314"
//     },
//     {
//      "id": 7,
//      "active": true,
//      "code": "011",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "ibank",
//      "is_deleted": false,
//      "name": "First Bank of Nigeria",
//      "pay_with_bank": false,
//      "slug": "first-bank-of-nigeria",
//      "type": "nuban",
//      "updatedAt": "2021-03-25T14:22:52.000Z",
//      "value": "011"
//     },
//     {
//      "id": 8,
//      "active": true,
//      "code": "214",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "First City Monument Bank",
//      "pay_with_bank": false,
//      "slug": "first-city-monument-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T08:06:46.000Z",
//      "value": "214"
//     },
//     {
//      "id": 682,
//      "active": true,
//      "code": "107",
//      "country": "Nigeria",
//      "createdAt": "2023-02-17T12:12:37.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "FirstTrust Mortgage Bank Nigeria",
//      "pay_with_bank": false,
//      "slug": "firsttrust-mortgage-bank-nigeria-ng",
//      "type": "nuban",
//      "updatedAt": "2023-02-17T12:12:37.000Z",
//      "value": "107"
//     },
//     {
//      "id": 687,
//      "active": true,
//      "code": "50315",
//      "country": "Nigeria",
//      "createdAt": "2023-03-09T08:11:06.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "FLOURISH MFB",
//      "pay_with_bank": false,
//      "slug": "flourish-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2023-03-09T08:11:06.000Z",
//      "value": "50315"
//     },
//     {
//      "id": 112,
//      "active": true,
//      "code": "501",
//      "country": "Nigeria",
//      "createdAt": "2020-08-20T09:37:04.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "FSDH Merchant Bank Limited",
//      "pay_with_bank": false,
//      "slug": "fsdh-merchant-bank-limited",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:03:22.000Z",
//      "value": "501"
//     },
//     {
//      "id": 287,
//      "active": true,
//      "code": "812",
//      "country": "Nigeria",
//      "createdAt": "2022-02-24T06:04:39.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Gateway Mortgage Bank LTD",
//      "pay_with_bank": false,
//      "slug": "gateway-mortgage-bank",
//      "type": "nuban",
//      "updatedAt": "2022-02-24T06:04:39.000Z",
//      "value": "812"
//     },
//     {
//      "id": 70,
//      "active": true,
//      "code": "00103",
//      "country": "Nigeria",
//      "createdAt": "2020-02-11T15:38:57.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Globus Bank",
//      "pay_with_bank": false,
//      "slug": "globus-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-11T15:38:57.000Z",
//      "value": "00103"
//     },
//     {
//      "id": 183,
//      "active": true,
//      "code": "100022",
//      "country": "Nigeria",
//      "createdAt": "2021-08-04T11:49:46.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "GoMoney",
//      "pay_with_bank": false,
//      "slug": "gomoney",
//      "type": "nuban",
//      "updatedAt": "2021-11-12T13:32:14.000Z",
//      "value": "100022"
//     },
//     {
//      "id": 635,
//      "active": true,
//      "code": "50739",
//      "country": "Nigeria",
//      "createdAt": "2022-09-29T09:14:18.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Goodnews Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "goodnews-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-10-18T14:59:07.000Z",
//      "value": "50739"
//     },
//     {
//      "id": 633,
//      "active": true,
//      "code": "562",
//      "country": "Nigeria",
//      "createdAt": "2022-09-16T15:23:58.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Greenwich Merchant Bank",
//      "pay_with_bank": false,
//      "slug": "greenwich-merchant-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-16T15:23:58.000Z",
//      "value": "562"
//     },
//     {
//      "id": 9,
//      "active": true,
//      "code": "058",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "ibank",
//      "is_deleted": false,
//      "name": "Guaranty Trust Bank",
//      "pay_with_bank": true,
//      "slug": "guaranty-trust-bank",
//      "type": "nuban",
//      "updatedAt": "2022-06-14T06:59:27.000Z",
//      "value": "058"
//     },
//     {
//      "id": 111,
//      "active": true,
//      "code": "51251",
//      "country": "Nigeria",
//      "createdAt": "2020-08-20T09:32:48.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Hackman Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "hackman-microfinance-bank",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:03:24.000Z",
//      "value": "51251"
//     },
//     {
//      "id": 81,
//      "active": true,
//      "code": "50383",
//      "country": "Nigeria",
//      "createdAt": "2020-02-11T15:38:57.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Hasal Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "hasal-microfinance-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-11T15:38:57.000Z",
//      "value": "50383"
//     },
//     {
//      "id": 10,
//      "active": true,
//      "code": "030",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Heritage Bank",
//      "pay_with_bank": false,
//      "slug": "heritage-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:24:23.000Z",
//      "value": "030"
//     },
//     {
//      "id": 301,
//      "active": true,
//      "code": "120002",
//      "country": "Nigeria",
//      "createdAt": "2022-05-30T14:03:18.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "HopePSB",
//      "pay_with_bank": false,
//      "slug": "hopepsb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-05-30T14:03:18.000Z",
//      "value": "120002"
//     },
//     {
//      "id": 168,
//      "active": true,
//      "code": "51244",
//      "country": "Nigeria",
//      "createdAt": "2020-10-21T10:54:20.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ibile Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "ibile-mfb",
//      "type": "nuban",
//      "updatedAt": "2020-10-21T10:54:33.000Z",
//      "value": "51244"
//     },
//     {
//      "id": 615,
//      "active": true,
//      "code": "50439",
//      "country": "Nigeria",
//      "createdAt": "2022-08-10T09:48:24.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ikoyi Osun MFB",
//      "pay_with_bank": false,
//      "slug": "ikoyi-osun-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-08-10T09:48:24.000Z",
//      "value": "50439"
//     },
//     {
//      "id": 636,
//      "active": true,
//      "code": "50442",
//      "country": "Nigeria",
//      "createdAt": "2022-10-12T09:15:26.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Ilaro Poly Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "ilaro-poly-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-10-12T09:15:26.000Z",
//      "value": "50442"
//     },
//     {
//      "id": 172,
//      "active": true,
//      "code": "50457",
//      "country": "Nigeria",
//      "createdAt": "2020-11-24T10:23:37.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Infinity MFB",
//      "pay_with_bank": false,
//      "slug": "infinity-mfb",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:23:37.000Z",
//      "value": "50457"
//     },
//     {
//      "id": 22,
//      "active": true,
//      "code": "301",
//      "country": "Nigeria",
//      "createdAt": "2016-10-10T17:26:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Jaiz Bank",
//      "pay_with_bank": false,
//      "slug": "jaiz-bank",
//      "type": "nuban",
//      "updatedAt": "2016-10-10T17:26:29.000Z",
//      "value": "301"
//     },
//     {
//      "id": 187,
//      "active": true,
//      "code": "50502",
//      "country": "Nigeria",
//      "createdAt": "2021-09-27T11:59:42.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Kadpoly MFB",
//      "pay_with_bank": false,
//      "slug": "kadpoly-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-09-27T11:59:42.000Z",
//      "value": "50502"
//     },
//     {
//      "id": 11,
//      "active": true,
//      "code": "082",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Keystone Bank",
//      "pay_with_bank": false,
//      "slug": "keystone-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:23:45.000Z",
//      "value": "082"
//     },
//     {
//      "id": 184,
//      "active": true,
//      "code": "50200",
//      "country": "Nigeria",
//      "createdAt": "2021-08-11T09:54:03.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Kredi Money MFB LTD",
//      "pay_with_bank": false,
//      "slug": "kredi-money-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-08-11T09:54:03.000Z",
//      "value": "50200"
//     },
//     {
//      "id": 67,
//      "active": true,
//      "code": "50211",
//      "country": "Nigeria",
//      "createdAt": "2019-11-15T17:06:54.000Z",
//      "currency": "NGN",
//      "gateway": "digitalbankmandate",
//      "is_deleted": false,
//      "name": "Kuda Bank",
//      "pay_with_bank": true,
//      "slug": "kuda-bank",
//      "type": "nuban",
//      "updatedAt": "2022-04-08T17:07:53.000Z",
//      "value": "50211"
//     },
//     {
//      "id": 109,
//      "active": true,
//      "code": "90052",
//      "country": "Nigeria",
//      "createdAt": "2020-08-10T15:07:44.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Lagos Building Investment Company Plc.",
//      "pay_with_bank": false,
//      "slug": "lbic-plc",
//      "type": "nuban",
//      "updatedAt": "2020-08-10T15:07:44.000Z",
//      "value": "90052"
//     },
//     {
//      "id": 180,
//      "active": true,
//      "code": "50549",
//      "country": "Nigeria",
//      "createdAt": "2021-07-12T14:41:18.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Links MFB",
//      "pay_with_bank": false,
//      "slug": "links-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-07-12T14:41:18.000Z",
//      "value": "50549"
//     },
//     {
//      "id": 296,
//      "active": true,
//      "code": "031",
//      "country": "Nigeria",
//      "createdAt": "2022-05-25T10:46:17.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Living Trust Mortgage Bank",
//      "pay_with_bank": false,
//      "slug": "living-trust-mortgage-bank",
//      "type": "nuban",
//      "updatedAt": "2022-05-25T10:46:17.000Z",
//      "value": "031"
//     },
//     {
//      "id": 233,
//      "active": true,
//      "code": "303",
//      "country": "Nigeria",
//      "createdAt": "2021-12-06T14:39:51.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Lotus Bank",
//      "pay_with_bank": false,
//      "slug": "lotus-bank",
//      "type": "nuban",
//      "updatedAt": "2021-12-06T14:39:51.000Z",
//      "value": "303"
//     },
//     {
//      "id": 175,
//      "active": true,
//      "code": "50563",
//      "country": "Nigeria",
//      "createdAt": "2021-02-02T08:28:38.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Mayfair MFB",
//      "pay_with_bank": false,
//      "slug": "mayfair-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-02-02T08:28:38.000Z",
//      "value": "50563"
//     },
//     {
//      "id": 178,
//      "active": true,
//      "code": "50304",
//      "country": "Nigeria",
//      "createdAt": "2021-06-01T16:07:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Mint MFB",
//      "pay_with_bank": false,
//      "slug": "mint-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-06-01T16:07:29.000Z",
//      "value": "50304"
//     },
//     {
//      "id": 688,
//      "active": true,
//      "code": "50515",
//      "country": "Nigeria",
//      "createdAt": "2023-03-20T12:53:58.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Moniepoint MFB",
//      "pay_with_bank": false,
//      "slug": "moniepoint-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2023-03-20T12:53:58.000Z",
//      "value": "50515"
//     },
//     {
//      "id": 303,
//      "active": true,
//      "code": "120003",
//      "country": "Nigeria",
//      "createdAt": "2022-05-31T06:52:07.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "MTN Momo PSB",
//      "pay_with_bank": false,
//      "slug": "mtn-momo-psb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-06-23T09:33:55.000Z",
//      "value": "120003"
//     },
//     {
//      "id": 185,
//      "active": true,
//      "code": "100002",
//      "country": "Nigeria",
//      "createdAt": "2021-08-31T08:10:00.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Paga",
//      "pay_with_bank": false,
//      "slug": "paga",
//      "type": "nuban",
//      "updatedAt": "2021-08-31T08:10:00.000Z",
//      "value": "100002"
//     },
//     {
//      "id": 169,
//      "active": true,
//      "code": "999991",
//      "country": "Nigeria",
//      "createdAt": "2020-11-24T09:58:37.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "PalmPay",
//      "pay_with_bank": false,
//      "slug": "palmpay",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:03:19.000Z",
//      "value": "999991"
//     },
//     {
//      "id": 26,
//      "active": true,
//      "code": "104",
//      "country": "Nigeria",
//      "createdAt": "2017-03-31T13:54:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Parallex Bank",
//      "pay_with_bank": false,
//      "slug": "parallex-bank",
//      "type": "nuban",
//      "updatedAt": "2021-10-29T08:00:19.000Z",
//      "value": "104"
//     },
//     {
//      "id": 110,
//      "active": true,
//      "code": "311",
//      "country": "Nigeria",
//      "createdAt": "2020-08-10T15:07:44.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Parkway - ReadyCash",
//      "pay_with_bank": false,
//      "slug": "parkway-ready-cash",
//      "type": "nuban",
//      "updatedAt": "2020-08-10T15:07:44.000Z",
//      "value": "311"
//     },
//     {
//      "id": 171,
//      "active": true,
//      "code": "999992",
//      "country": "Nigeria",
//      "createdAt": "2020-11-24T10:20:45.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Paycom",
//      "pay_with_bank": false,
//      "slug": "paycom",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:20:54.000Z",
//      "value": "999992"
//     },
//     {
//      "id": 693,
//      "active": true,
//      "code": "50743",
//      "country": "Nigeria",
//      "createdAt": "2023-04-12T16:51:04.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Peace Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "peace-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2023-04-12T16:51:04.000Z",
//      "value": "50743"
//     },
//     {
//      "id": 683,
//      "active": true,
//      "code": "51146",
//      "country": "Nigeria",
//      "createdAt": "2023-02-17T12:13:28.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Personal Trust MFB",
//      "pay_with_bank": false,
//      "slug": "personal-trust-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2023-02-17T12:13:28.000Z",
//      "value": "51146"
//     },
//     {
//      "id": 170,
//      "active": true,
//      "code": "50746",
//      "country": "Nigeria",
//      "createdAt": "2020-11-24T10:03:06.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Petra Mircofinance Bank Plc",
//      "pay_with_bank": false,
//      "slug": "petra-microfinance-bank-plc",
//      "type": "nuban",
//      "updatedAt": "2020-11-24T10:03:06.000Z",
//      "value": "50746"
//     },
//     {
//      "id": 13,
//      "active": true,
//      "code": "076",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Polaris Bank",
//      "pay_with_bank": false,
//      "slug": "polaris-bank",
//      "type": "nuban",
//      "updatedAt": "2016-07-14T10:04:29.000Z",
//      "value": "076"
//     },
//     {
//      "id": 626,
//      "active": true,
//      "code": "50864",
//      "country": "Nigeria",
//      "createdAt": "2022-08-17T17:27:23.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Polyunwana MFB",
//      "pay_with_bank": false,
//      "slug": "polyunwana-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-08-17T17:27:23.000Z",
//      "value": "50864"
//     },
//     {
//      "id": 304,
//      "active": true,
//      "code": "105",
//      "country": "Nigeria",
//      "createdAt": "2022-06-01T14:16:02.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "PremiumTrust Bank",
//      "pay_with_bank": false,
//      "slug": "premiumtrust-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-08-17T08:13:08.000Z",
//      "value": "105"
//     },
//     {
//      "id": 25,
//      "active": true,
//      "code": "101",
//      "country": "Nigeria",
//      "createdAt": "2017-03-27T16:09:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Providus Bank",
//      "pay_with_bank": false,
//      "slug": "providus-bank",
//      "type": "nuban",
//      "updatedAt": "2021-02-09T17:50:06.000Z",
//      "value": "101"
//     },
//     {
//      "id": 232,
//      "active": true,
//      "code": "51293",
//      "country": "Nigeria",
//      "createdAt": "2021-10-29T08:43:35.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "QuickFund MFB",
//      "pay_with_bank": false,
//      "slug": "quickfund-mfb",
//      "type": "nuban",
//      "updatedAt": "2021-10-29T08:43:35.000Z",
//      "value": "51293"
//     },
//     {
//      "id": 176,
//      "active": true,
//      "code": "502",
//      "country": "Nigeria",
//      "createdAt": "2021-02-11T17:33:20.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Rand Merchant Bank",
//      "pay_with_bank": false,
//      "slug": "rand-merchant-bank",
//      "type": "nuban",
//      "updatedAt": "2021-02-11T17:33:20.000Z",
//      "value": "502"
//     },
//     {
//      "id": 295,
//      "active": true,
//      "code": "90067",
//      "country": "Nigeria",
//      "createdAt": "2022-05-25T10:46:17.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Refuge Mortgage Bank",
//      "pay_with_bank": false,
//      "slug": "refuge-mortgage-bank",
//      "type": "nuban",
//      "updatedAt": "2022-05-25T10:46:17.000Z",
//      "value": "90067"
//     },
//     {
//      "id": 679,
//      "active": true,
//      "code": "50767",
//      "country": "Nigeria",
//      "createdAt": "2022-12-20T13:41:50.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "ROCKSHIELD MICROFINANCE BANK",
//      "pay_with_bank": false,
//      "slug": "rockshield-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-12-20T14:27:14.000Z",
//      "value": "50767"
//     },
//     {
//      "id": 69,
//      "active": true,
//      "code": "125",
//      "country": "Nigeria",
//      "createdAt": "2020-01-25T09:49:59.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Rubies MFB",
//      "pay_with_bank": false,
//      "slug": "rubies-mfb",
//      "type": "nuban",
//      "updatedAt": "2020-01-25T09:49:59.000Z",
//      "value": "125"
//     },
//     {
//      "id": 286,
//      "active": true,
//      "code": "51113",
//      "country": "Nigeria",
//      "createdAt": "2022-02-18T13:11:59.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "Safe Haven MFB",
//      "pay_with_bank": false,
//      "slug": "safe-haven-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-02-18T13:11:59.000Z",
//      "value": "51113"
//     },
//     {
//      "id": 609,
//      "active": true,
//      "code": "951113",
//      "country": "Nigeria",
//      "createdAt": "2022-07-28T14:22:56.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Safe Haven Microfinance Bank Limited",
//      "pay_with_bank": false,
//      "slug": "safe-haven-microfinance-bank-limited-ng",
//      "type": "nuban",
//      "updatedAt": "2022-12-02T10:51:53.000Z",
//      "value": "951113"
//     },
//     {
//      "id": 632,
//      "active": true,
//      "code": "50582",
//      "country": "Nigeria",
//      "createdAt": "2022-09-16T15:16:47.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Shield MFB",
//      "pay_with_bank": false,
//      "slug": "shield-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-16T15:16:47.000Z",
//      "value": "50582"
//     },
//     {
//      "id": 365,
//      "active": true,
//      "code": "50800",
//      "country": "Nigeria",
//      "createdAt": "2022-06-27T10:24:28.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Solid Rock MFB",
//      "pay_with_bank": false,
//      "slug": "solid-rock-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-06-27T10:24:28.000Z",
//      "value": "50800"
//     },
//     {
//      "id": 72,
//      "active": true,
//      "code": "51310",
//      "country": "Nigeria",
//      "createdAt": "2020-02-11T18:43:14.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Sparkle Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "sparkle-microfinance-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-11T18:43:14.000Z",
//      "value": "51310"
//     },
//     {
//      "id": 14,
//      "active": true,
//      "code": "221",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Stanbic IBTC Bank",
//      "pay_with_bank": false,
//      "slug": "stanbic-ibtc-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:24:17.000Z",
//      "value": "221"
//     },
//     {
//      "id": 15,
//      "active": true,
//      "code": "068",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Standard Chartered Bank",
//      "pay_with_bank": false,
//      "slug": "standard-chartered-bank",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:23:40.000Z",
//      "value": "068"
//     },
//     {
//      "id": 285,
//      "active": true,
//      "code": "51253",
//      "country": "Nigeria",
//      "createdAt": "2022-02-17T14:54:01.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Stellas MFB",
//      "pay_with_bank": false,
//      "slug": "stellas-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-02-17T14:54:01.000Z",
//      "value": "51253"
//     },
//     {
//      "id": 16,
//      "active": true,
//      "code": "232",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Sterling Bank",
//      "pay_with_bank": false,
//      "slug": "sterling-bank",
//      "type": "nuban",
//      "updatedAt": "2022-05-27T08:56:01.000Z",
//      "value": "232"
//     },
//     {
//      "id": 23,
//      "active": true,
//      "code": "100",
//      "country": "Nigeria",
//      "createdAt": "2016-10-10T17:26:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Suntrust Bank",
//      "pay_with_bank": false,
//      "slug": "suntrust-bank",
//      "type": "nuban",
//      "updatedAt": "2016-10-10T17:26:29.000Z",
//      "value": "100"
//     },
//     {
//      "id": 631,
//      "active": true,
//      "code": "50968",
//      "country": "Nigeria",
//      "createdAt": "2022-09-16T15:16:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Supreme MFB",
//      "pay_with_bank": false,
//      "slug": "supreme-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-16T15:16:29.000Z",
//      "value": "50968"
//     },
//     {
//      "id": 68,
//      "active": true,
//      "code": "302",
//      "country": "Nigeria",
//      "createdAt": "2020-01-20T11:20:32.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "TAJ Bank",
//      "pay_with_bank": false,
//      "slug": "taj-bank",
//      "type": "nuban",
//      "updatedAt": "2020-01-20T11:20:32.000Z",
//      "value": "302"
//     },
//     {
//      "id": 634,
//      "active": true,
//      "code": "090560",
//      "country": "Nigeria",
//      "createdAt": "2022-09-22T09:14:25.000Z",
//      "currency": "NGN",
//      "gateway": "ibank",
//      "is_deleted": false,
//      "name": "Tanadi Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "tanadi-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-22T09:14:25.000Z",
//      "value": "090560"
//     },
//     {
//      "id": 186,
//      "active": true,
//      "code": "51269",
//      "country": "Nigeria",
//      "createdAt": "2021-09-17T13:25:16.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Tangerine Money",
//      "pay_with_bank": false,
//      "slug": "tangerine-money",
//      "type": "nuban",
//      "updatedAt": "2021-09-17T13:25:16.000Z",
//      "value": "51269"
//     },
//     {
//      "id": 75,
//      "active": true,
//      "code": "51211",
//      "country": "Nigeria",
//      "createdAt": "2020-04-03T09:34:35.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "TCF MFB",
//      "pay_with_bank": false,
//      "slug": "tcf-mfb",
//      "type": "nuban",
//      "updatedAt": "2020-04-03T09:34:35.000Z",
//      "value": "51211"
//     },
//     {
//      "id": 73,
//      "active": true,
//      "code": "102",
//      "country": "Nigeria",
//      "createdAt": "2020-03-10T11:41:36.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Titan Bank",
//      "pay_with_bank": false,
//      "slug": "titan-bank",
//      "type": "nuban",
//      "updatedAt": "2020-03-23T15:06:29.000Z",
//      "value": "102"
//     },
//     {
//      "id": 629,
//      "active": true,
//      "code": "100039",
//      "country": "Nigeria",
//      "createdAt": "2022-09-02T08:51:15.000Z",
//      "currency": "NGN",
//      "gateway": "",
//      "is_deleted": false,
//      "name": "Titan Paystack",
//      "pay_with_bank": false,
//      "slug": "titan-paystack",
//      "type": "nuban",
//      "updatedAt": "2022-09-06T09:40:48.000Z",
//      "value": "100039"
//     },
//     {
//      "id": 690,
//      "active": true,
//      "code": "50840",
//      "country": "Nigeria",
//      "createdAt": "2023-03-27T16:55:53.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "U&C Microfinance Bank Ltd (U AND C MFB)",
//      "pay_with_bank": false,
//      "slug": "uc-microfinance-bank-ltd-u-and-c-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2023-03-27T16:55:53.000Z",
//      "value": "50840"
//     },
//     {
//      "id": 630,
//      "active": true,
//      "code": "MFB51322",
//      "country": "Nigeria",
//      "createdAt": "2022-09-14T12:58:20.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Uhuru MFB",
//      "pay_with_bank": false,
//      "slug": "uhuru-mfb-ng",
//      "type": "nuban",
//      "updatedAt": "2022-09-14T12:58:20.000Z",
//      "value": "MFB51322"
//     },
//     {
//      "id": 678,
//      "active": true,
//      "code": "50870",
//      "country": "Nigeria",
//      "createdAt": "2022-11-24T13:47:10.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Unaab Microfinance Bank Limited",
//      "pay_with_bank": false,
//      "slug": "unaab-microfinance-bank-limited-ng",
//      "type": "nuban",
//      "updatedAt": "2022-11-24T13:49:16.000Z",
//      "value": "50870"
//     },
//     {
//      "id": 282,
//      "active": true,
//      "code": "50871",
//      "country": "Nigeria",
//      "createdAt": "2022-01-10T09:52:47.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Unical MFB",
//      "pay_with_bank": false,
//      "slug": "unical-mfb",
//      "type": "nuban",
//      "updatedAt": "2022-01-10T09:52:47.000Z",
//      "value": "50871"
//     },
//     {
//      "id": 638,
//      "active": true,
//      "code": "51316",
//      "country": "Nigeria",
//      "createdAt": "2022-11-07T07:41:50.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Unilag Microfinance Bank",
//      "pay_with_bank": false,
//      "slug": "unilag-microfinance-bank-ng",
//      "type": "nuban",
//      "updatedAt": "2022-11-07T07:41:50.000Z",
//      "value": "51316"
//     },
//     {
//      "id": 17,
//      "active": true,
//      "code": "032",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Union Bank of Nigeria",
//      "pay_with_bank": false,
//      "slug": "union-bank-of-nigeria",
//      "type": "nuban",
//      "updatedAt": "2020-02-18T20:22:54.000Z",
//      "value": "032"
//     },
//     {
//      "id": 18,
//      "active": true,
//      "code": "033",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "United Bank For Africa",
//      "pay_with_bank": false,
//      "slug": "united-bank-for-africa",
//      "type": "nuban",
//      "updatedAt": "2022-03-09T10:28:57.000Z",
//      "value": "033"
//     },
//     {
//      "id": 19,
//      "active": true,
//      "code": "215",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Unity Bank",
//      "pay_with_bank": false,
//      "slug": "unity-bank",
//      "type": "nuban",
//      "updatedAt": "2019-07-22T12:44:02.000Z",
//      "value": "215"
//     },
//     {
//      "id": 71,
//      "active": true,
//      "code": "566",
//      "country": "Nigeria",
//      "createdAt": "2020-02-11T15:44:11.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "VFD Microfinance Bank Limited",
//      "pay_with_bank": false,
//      "slug": "vfd",
//      "type": "nuban",
//      "updatedAt": "2020-10-28T09:42:08.000Z",
//      "value": "566"
//     },
//     {
//      "id": 20,
//      "active": true,
//      "code": "035",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": null,
//      "is_deleted": false,
//      "name": "Wema Bank",
//      "pay_with_bank": false,
//      "slug": "wema-bank",
//      "type": "nuban",
//      "updatedAt": "2021-02-09T17:49:59.000Z",
//      "value": "035"
//     },
//     {
//      "id": 21,
//      "active": true,
//      "code": "057",
//      "country": "Nigeria",
//      "createdAt": "2016-07-14T10:04:29.000Z",
//      "currency": "NGN",
//      "gateway": "emandate",
//      "is_deleted": false,
//      "name": "Zenith Bank",
//      "pay_with_bank": false,
//      "slug": "zenith-bank",
//      "type": "nuban",
//      "updatedAt": "2023-02-18T21:01:50.000Z",
//      "value": "057"
//     }
// ]

export let nigeriaBanks =[
    {
        "name": "Access Bank"
    },
    {
        "name": "Chamsmobile"
    },
    {
        "name": "Citibank"
    },
    {
        "name": "Access Diamond Bank"
    },
    {
        "name": "Ecobank"
    },
    {
        "name": "Enterprise Bank"
    },
    {
        "name": "FCMB"
    },
    {
        "name": "Fidelity Bank"
    },
    {
        "name": "First Bank"
    },
    {
        "name": "GTBank"
    },
    {
        "name": "Heritage Bank"
    },
    {
        "name": "Jaiz"
    },
    {
        "name": "Keystone Bank"
    },
    {
        "name": "MainStreet Bank"
    },
    {
        "name": "Skye Bank"
    },
    {
        "name": "Stanbic IBTC"
    },
    {
        "name": "Standard Chartered"
    },
    {
        "name": "Sterling Bank"
    },
    {
        "name": "SunTrust Bank"
    },
    {
        "name": "UBA"
    },
    {
        "name": "Union Bank"
    },
    {
        "name": "Unity Bank"
    },
    {
        "name": "Wema Bank"
    },
    {
        "name": "Zenith Bank"
    },
    {
        "name": "ProvidusBank PLC"
    },
    {
        "name": "Globus Bank"
    },
    {
        "name": "Paycom"
    },
    {
        "name": "Paga"
    },
    {
        "name": "Rand merchant Bank"
    },
    {
        "name": "FINATRUST MICROFINANCE BANK"
    },
    {
        "name": "FET"
    },
    {
        "name": "Parkway-ReadyCash"
    },
    {
        "name": "Cellulant"
    },
    {
        "name": "eTranzact"
    },
    {
        "name": "Stanbic IBTC @ease wallet"
    },
    {
        "name": "Ecobank Xpress Account"
    },
    {
        "name": "GTMobile"
    },
    {
        "name": "TeasyMobile"
    },
    {
        "name": "Mkudi"
    },
    {
        "name": "VTNetworks"
    },
    {
        "name": "AccessMobile"
    },
    {
        "name": "FBNMobile"
    },
    {
        "name": "Kegow"
    },
    {
        "name": "FortisMobile"
    },
    {
        "name": "Hedonmark"
    },
    {
        "name": "ZenithMobile"
    },
    {
        "name": "Fidelity Mobile"
    },
    {
        "name": "MoneyBox"
    },
    {
        "name": "Eartholeum"
    },
    {
        "name": "GoMoney"
    },
    {
        "name": "TagPay"
    },
    {
        "name": "Imperial Homes Mortgage Bank"
    },
    {
        "name": "Zinternet Nigera Limited"
    },
    {
        "name": "One Finance"
    },
    {
        "name": "Intellifin"
    },
    {
        "name": "AG Mortgage Bank"
    },
    {
        "name": "Innovectives Kesh"
    },
    {
        "name": "EcoMobile"
    },
    {
        "name": "FCMB Easy Account"
    },
    {
        "name": "Contec Global Infotech Limited (NowNow)"
    },
    {
        "name": "PayAttitude Online"
    },
    {
        "name": "Flutterwave Technology Solutions Limited"
    },
    {
        "name": "FSDH Merchant Bank"
    },
    {
        "name": "NIP Virtual Bank"
    },
    {
        "name": "AL-Barakah Microfinance Bank"
    },
    {
        "name": "AB Microfinance Bank"
    },
    {
        "name": "Abbey Mortgage Bank"
    },
    {
        "name": "Above Only Microfinance Bank"
    },
    {
        "name": "ABU Microfinance Bank"
    },
    {
        "name": "Accion Microfinance Bank"
    },
    {
        "name": "Addosser Microfinance Bank"
    },
    {
        "name": "Adeyemi College Staff Microfinance Bank"
    },
    {
        "name": "Al-Hayat Microfinance Bank"
    },
    {
        "name": "Alekun Microfinance Bank"
    },
    {
        "name": "Allworkers Microfinance Bank"
    },
    {
        "name": "Alpha Kapital Microfinance Bank"
    },
    {
        "name": "AMJU Unique Microfinance Bank"
    },
    {
        "name": "AMML MFB"
    },
    {
        "name": "Apeks Microfinance Bank"
    },
    {
        "name": "ASOSavings & Loans"
    },
    {
        "name": "Astrapolaris Microfinance Bank"
    },
    {
        "name": "Auchi Microfinance Bank"
    },
    {
        "name": "Baines Credit Microfinance Bank"
    },
    {
        "name": "Baobab Microfinance Bank"
    },
    {
        "name": "BC Kash Microfinance Bank"
    },
    {
        "name": "Boctrust Microfinance Bank"
    },
    {
        "name": "Bosak Microfinance Bank"
    },
    {
        "name": "Bowen Microfinance Bank"
    },
    {
        "name": "Brent Mortgage Bank"
    },
    {
        "name": "BRIDGEWAY MICROFINANCE BANK"
    },
    {
        "name": "CEMCS Microfinance Bank"
    },
    {
        "name": "Chikum Microfinance Bank"
    },
    {
        "name": "CIT Microfinance Bank"
    },
    {
        "name": "Consumer Microfinance Bank"
    },
    {
        "name": "Coronation Merchant Bank"
    },
    {
        "name": "Covenant Microfinance Bank"
    },
    {
        "name": "Credit Afrique Microfinance Bank"
    },
    {
        "name": "Daylight Microfinance Bank"
    },
    {
        "name": "e-Barcs Microfinance Bank"
    },
    {
        "name": "Ekondo MFB"
    },
    {
        "name": "Emeralds Microfinance Bank"
    },
    {
        "name": "Empire trust MFB"
    },
    {
        "name": "Esan Microfinance Bank"
    },
    {
        "name": "Eso-E Microfinance Bank"
    },
    {
        "name": "Eyowo MFB"
    },
    {
        "name": "FAST Microfinance Bank"
    },
    {
        "name": "FBN Mortgages Limited"
    },
    {
        "name": "FBNQUEST Merchant Bank"
    },
    {
        "name": "FFS Microfinance Bank"
    },
    {
        "name": "Fidfund Microfinance Bank"
    },
    {
        "name": "First Generation Mortgage Bank"
    },
    {
        "name": "First Royal Microfinance Bank"
    },
    {
        "name": "Fortis Microfinance Bank"
    },
    {
        "name": "Fullrange Microfinance Bank"
    },
    {
        "name": "Futo Microfinance Bank"
    },
    {
        "name": "Gashua Microfinance Bank"
    },
    {
        "name": "Gateway Mortgage Bank"
    },
    {
        "name": "Gowans Microfinance Bank"
    },
    {
        "name": "GreenBank Microfinance Bank"
    },
    {
        "name": "Greenville Microfinance Bank"
    },
    {
        "name": "Grooming Microfinance Bank"
    },
    {
        "name": "Hackman Microfinance Bank"
    },
    {
        "name": "Haggai Mortgage Bank Limited"
    },
    {
        "name": "Hasal Microfinance Bank"
    },
    {
        "name": "Rubies Microfinance Bank"
    },
    {
        "name": "IBILE Microfinance Bank"
    },
    {
        "name": "Imo State Microfinance Bank"
    },
    {
        "name": "Infinity Microfinance Bank"
    },
    {
        "name": "Infinity Trust Mortgage Bank"
    },
    {
        "name": "IRL Microfinance Bank"
    },
    {
        "name": "Jubilee-Life Mortgage Bank"
    },
    {
        "name": "KCMB Microfinance Bank"
    },
    {
        "name": "Kuda"
    },
    {
        "name": "La Fayette Microfinance Bank"
    },
    {
        "name": "Lagos Building Investment Company"
    },
    {
        "name": "Lapo Microfinance Bank"
    },
    {
        "name": "Lavender Microfinance Bank"
    },
    {
        "name": "Lovonus Microfinance Bank"
    },
    {
        "name": "Malachy Microfinance Bank"
    },
    {
        "name": "Midland Microfinance Bank"
    },
    {
        "name": "Mint-Finex MICROFINANCE BANK"
    },
    {
        "name": "Money Trust Microfinance Bank"
    },
    {
        "name": "Mutual Benefits Microfinance Bank"
    },
    {
        "name": "Mutual Trust Microfinance Bank"
    },
    {
        "name": "Nagarta Microfinance Bank"
    },
    {
        "name": "Navy Microfinance Bank"
    },
    {
        "name": "Ndiorah Microfinance Bank"
    },
    {
        "name": "New Dawn Microfinance Bank"
    },
    {
        "name": "New Prudential Bank"
    },
    {
        "name": "NIRSAL Microfinance Bank"
    },
    {
        "name": "Nova Merchant Bank"
    },
    {
        "name": "NPF MicroFinance Bank"
    },
    {
        "name": "Ohafia Microfinance Bank"
    },
    {
        "name": "Okpoga Microfinance Bank"
    },
    {
        "name": "Olabisi Onabanjo University Microfinance Bank"
    },
    {
        "name": "Omoluabi savings and loans"
    },
    {
        "name": "Page Financials"
    },
    {
        "name": "Parralex bank"
    },
    {
        "name": "PatrickGold Microfinance Bank"
    },
    {
        "name": "PecanTrust Microfinance Bank"
    },
    {
        "name": "Pennywise Microfinance Bank"
    },
    {
        "name": "Personal Trust Microfinance Bank"
    },
    {
        "name": "Petra Microfinance Bank"
    },
    {
        "name": "Platinum Mortgage Bank"
    },
    {
        "name": "Polaris bank"
    },
    {
        "name": "Quickfund Microfinance Bank"
    },
    {
        "name": "Refuge Mortgage Bank"
    },
    {
        "name": "Regent Microfinance Bank"
    },
    {
        "name": "Reliance Microfinance Bank"
    },
    {
        "name": "RenMoney Microfinance Bank"
    },
    {
        "name": "Richway Microfinance Bank"
    },
    {
        "name": "Royal Exchange Microfinance Bank"
    },
    {
        "name": "SafeTrust"
    },
    {
        "name": "Sagamu Microfinance Bank"
    },
    {
        "name": "Seed Capital Microfinance Bank"
    },
    {
        "name": "Sparkle"
    },
    {
        "name": "Stanford Microfinance Bak"
    },
    {
        "name": "Stellas Microfinance Bank"
    },
    {
        "name": "Taj Bank Limited"
    },
    {
        "name": "TCF MFB"
    },
    {
        "name": "Titan Trust Bank"
    },
    {
        "name": "Trident Microfinance Bank"
    },
    {
        "name": "Trustbond Mortgage Bank"
    },
    {
        "name": "Trustfund Microfinance Bank"
    },
    {
        "name": "Uniben Microfinance Bank"
    },
    {
        "name": "Unical Microfinance Bank"
    },
    {
        "name": "UNN MFB"
    },
    {
        "name": "Verite Microfinance Bank"
    },
    {
        "name": "VFD Micro Finance Bank"
    },
    {
        "name": "Virtue Microfinance Bank"
    },
    {
        "name": "Visa Microfinance Bank"
    },
    {
        "name": "Wetland Microfinance Bank"
    },
    {
        "name": "Xslnce Microfinance Bank"
    },
    {
        "name": "Yes Microfinance Bank"
    },
    {
        "name": "Bubayero MFB"
    },
    {
        "name": "Yobe Micro Finance Bank"
    },
    {
        "name": "Nirsal Micro financial Bank"
    },
    {
        "name": "Rima growth pathway Micro Finance Bank"
    },
    {
        "name": "Nkpolu- UST micro Finance Bank"
    },
    {
        "name": "Stanford Micro Finance Bank"
    },
    {
        "name": "Lapo Microfinance Bank"
    },
    {
        "name": "BIPC Microfinance Bank"
    },
    {
        "name": "Palmpay"
    },
    {
        "name": "Lotus"
    },
    {
        "name": "Opay"
    }
]