export let ministryParastatalData = [
    {
        // ministry: "Office of the Vice President",
        ministry: "Presidency/State house",
        parastatal: [
            "National Boundary Commission (NBC)",
            "Border Communities Development Agencies (BCDA)",
            "National Institute for Policy and Strategic Studies (NIPPS)",
            "National Emergency Management (NEMA)",
            "National Commission for Refugees (NCR)",
            "Debt Management Office (DMO)",
            "Niger Delta Power Holding Company (NDPHC)",
            "The National Planning Commission",
            "National Bureau of Statistics (NBS)",
            "Centre for Management Development (CMD)",
            "Nigerian Institute of Social and Economic Research (NISER)",
        ]
    },
    {
        ministry: "Office of the Secretary to the Government of the Federation",
        parastatal: [
            "National Poverty Eradication Programme (NAPEP)",
            "Nigerian National Merit Award (NNMA)",
            "Federal Road Safety Commission (FRSC)",
            "Niger Delta Development Commission (NDDC)",
            "New Partnership for Africa’s Development (NEPAD)",
            "Utilities Charges Commission (UCC)",
            "National Agency for the Control of HIV/AIDS (NACA)",
            "National Hajj Commission of Nigeria (NAHCON)",
            "Nigerian Christian Pilgrims Commission (NCPC)",
            "National Lottery Regulatory Commission (NLRC)",
            "National Lottery Trust Fund (NLTF)",
            "Service Compact with All Nigerians (SERVICOM)",
            "Nigerian Extractive Industries Transparency Initiative (NEITI)",
            "National Economic intelligence Committee (NEIC)",
            "Code of Conduct Bureau/Code of Conduct Tribunal",
        ]
    },
    {
        ministry: "Office of the Head of the Civil Service of the Federation",
        parastatal: [
            "Administrative Staff College of Nigeria (ASCON)",
            "Public Service Institute of Nigeria (PSIN)",
            "Bureau of Public Service Reforms (BPSR)",
            "Federal Government Staff Housing Loans Board (FGSHLB)",
            "Agricultural Research Council of Nigeria (ARCN)",
            "Agricultural Research and Rural Management Institute (ARMTI)",
            "National Centre for Agricultural Mechanization (NCAM)",
            "Bank of Agriculture (BOA)",
            "National Livestock Development Project (NLDP), Kaduna",
            "National Agricultural Insurance Corporation (NAIC)",
            "National Food Reserve Agency",
            "Nigeria Agricultural Quarantine Service",
            "National Agriculture Seeds Council (NASC)",
            "Veterinary Council of Nigeria",
        ]
    },
    {
        ministry: "Ministry of Aviation",
        parastatal: [
            "Nigerian Civil Aviation Authority (NCAA)",
            "Nigerian Airspace Management Agency (NAMA)",
            "Accident Investigation Bureau (AIB)",
            "Nigerian Meteorological Agency (NIMET)",
            "Federal Airports Authority of Nigeria (FAAN)",
            "Nigeria College of Aviation (NCAT)",
        ]
    },
    {
        // ministry: "Ministry of Communication Technology",
        ministry: "Ministry of Communication, Innovation and Digital Economy",
        parastatal: [
            "Nigerian Communications Commission (NCC)",
            "National Information Technology Development Agency (NITDA)",
            "Universal Service Provision Fund (USPF)",
            "Nigerian Postal Agency (NIPOST)",
            // "Nigerian Telecommunications Limited (NITEL)",
            "Galaxy Backbone Limited (GBL)",
            "Nigerian Communications Satellite (NigComSat) Limited",
        ]
    },
    {
        ministry: "Ministry of Defence",
        parastatal: [
            "Defence Industries Corporation of Nigeria (DICON)",
            "National Defence College (NDC)",
            "Nigerian Defence Academy (NDA)",
            "Armed Forces Command and Staff College (AFCSC)",
            "Military Pensions Board (MPB)",
            "Defence Intelligence Agency (DIA)",
            "Nigerian Armed Forces Resettlement Centre (NAFRC)",
            "Presidential Committee on Barracks Rehabilitation (PCBR)",
            "Defence Health Maintenance Company Limited",
        ]
    },
    {
        ministry: "Federal Ministry of Education",
        parastatal: [
            "West African Examination Council (WAEC)",
            "National examination Council (NECO)",
            "Joint Admission and Matriculation Board (JAMB)",
            "National Universities Commission (NUC)",
            "National Board of Technical Education (NBTE)",
            "National Commission for Colleges of Education (NCCE)",
            "Tertiary Education Trust Fund (TETFund)",
            "National Open University of Nigeria (NOUN)",
            "National Library of Nigeria",
            "Universal Basic Education Commission (UBEC)",
            "National Commission for Nomadic Education",
            "National Commission for Mass Literacy, Adult and Non-Formal Education",
            "National Teachers’ Institute (NTI)",
            "Nigerian Institute for Educational Planners and Administrators (NIEPA)",
            "Nigerian Educational Research and Development Council (NERDC)",
            "The Librarians’ Registration Council of Nigeria (LRCN)",
            "Teachers registration Council of Nigeria (TRCN)",
            "Computer Professionals Council of Nigeria (CPRCN)",
            "The National Mathematical Centre (NMC)",
            "French Language Village",
            "Arabic Language Village",
            "Nigerian French Language village (NFLV)",
            "Nigerian Arabic Language Village (NALV)",
            "National Oil Spill Detection and Response Agency (NOSDRA)",
            "National Environmental Standards and Regulations Enforcement Agency (NESREA)",
            "National Park Headquarters",
            "Forestry Research Institute of Nigeria, Ibadan",
            "Environmental Health Registration Council of Nigeria (EHRCN)",
        ]
    },
    {
        ministry: "Ministry of the Federal Capital Territory",
        parastatal: [
            "Federal Capital Territory Authority"
        ]
    },
    {
        ministry: "Federal Ministry of Finance",
        parastatal: [
            "Federal Inland Revenue (FIRS)",
            "Office of the Accountant General of the Federation",
            "Ministry of finance Incorporated (MOFI)",
            "Nigeria Customs Service (NCS)",
            "Investment and Securities Tribunal",
            "National Insurance Commission (NAICOM)",
            "National Economic Recovery Fund (NERFUND)",
            "Nigerian Import and Export Bank (NEXIM)",
            "Security and Exchange Commission (SEC)",
            "Nigerian Deposit Insurance Corporation (NDIC)",
        ]
    },
    {
        ministry: "Ministry of Foreign Affairs",
        parastatal: [
            "The Nigerian Institute of Internal Affairs (NIIA)",
            "Institute for Peace and Conflict Resolution",
            "The Directorate of Technical Cooperation in Africa (DTCA)",
            "The Directorate of Technical Aid Corps (DTAC)",
        ]
    },
    {
        ministry: "Federal Ministry of Health",
        parastatal: [
            "Teaching Hospitals",
            "Federal Medical Centres",
            "Specialty Hospitals",
            "Medical Research Centres",
            "Training Institutes",
            "Regulatory Bodies such as Nursing and Midwifery Council; Medical and Dental Council of Nigeria; Medical Laboratory Science Council of Nigeria; Radiographers Registration Board; Dental Technicians Registration Board; Optometrists and Dispensing Opticians Registration Board; Medical Rehabilitation Therapy Board; Dental Therapy Registration Board; Pharmacists Council of Nigeria; Institute of Chartered Chemists; Institute of Public Analysts; and Health Records Registration Board.",
            "NAFDAC",
            "Other Agencies under the Federal Ministry of Health",
        ]
    },
    {
        ministry: "Federal Ministry of Information",
        parastatal: [
            "Federal Radio Corporation (FRCN)",
            "Voice of Nigeria (VON)",
            "Nigerian Television Authority (NTA)",
            "News Agency of Nigeria (NAN)",
            "National Broadcasting Commission (NBC)",
            "Nigeria Film Corporation (NFC)",
            "National Film Video Censor Board (NFVCB)",
            "Advertising Practitioners Council of Nigeria (APCON)",
            "Nigerian Press Council (NPC)",
        ]
    },
    {
        ministry: "Ministry of Interior",
        parastatal: [
            "Nigerian Immigration Service (NIS)",
            "Nigerian Prisons Service (NPS)",
            "Nigerian Security and Council Defence Corps (NSCDC)",
            "Federal Fire Service",
            "Civil Defence, Immigration and Prisons Services Board (CDIPB)",
            "Customs, Immigration and Prisons Pension Office (CIPPO)",
            "National Identity Management Commission (NIMC)",
        ]
    },
    {
        ministry: "Ministry of Justice",
        parastatal: [
            "Council of Legal Education, Nigerian Law School (CLE & NLS)",
            "Legal Aid Council (LAC)",
            "Nigerian Copyright Commission",
            "Nigerian Law Reform Commission (NLRC)",
            "National Agency for Prohibition of Trafficking in Persons and other related Matters (NAPTIP)",
            "Nigerian Institute of Advanced Legal Studies (NIALS)",
            "National Law Drug Enforcement Agency (NDLEA)",
            "Regional Centre for International Commercial Arbitration",
        ]
    },
    {
        ministry: "Ministry of Labour and Productivity",
        parastatal: [
            "National Directorate of Employment (NDE)",
            "National Productivity Centre (NPC)",
            "Michael Imoudu National Institute of Labour Studies (MINILS)",
            "Industrial Arbitration Panel (IAP)",
            "Nigeria Social Insurance Trust Fund (NSITF)",
        ]
    },
    {
        ministry: "Ministry of Lands, Housing and Urban Development",
        parastatal: [
            "Federal Housing Authority (FHA)",
            "Federal Mortgage Bank of Nigeria (FMBN)"
        ]
    },
    {
        ministry: "Professional Registration Council",
        parastatal: [
            "Architects Registration Council (ARC).",
            "Council for Registered Engineers of Nigeria (COREN)",
            "Estate Surveyors’ Registration Board (ESRB)",
            "Town Planners Council (TPC)",
            "Nigerian Builders Council (NBC)",
            "Quantity Surveyors’ Registration Board of Nigeria (QSRB)",
            "Nigerian Bar Association (NBA)",
        ]
    },
    {
        ministry: "Ministry of Mines and Steel Development",
        parastatal: [
            "Council of Nigerian Mining Engineers and Geoscientists (COMEG)",
            "Nigerian Mining Cadastre Office (NMCO)",
            "National Steel Raw Materials Exploration Agency (NSRMEA)",
            "Nigerian Geological Survey Agency (NGSA)",
            "National Metallurgical Development Centre (NMDC), Jos",
            "National Metallurgical Training Institute, Onitsha",
            "Nigerian Institute of Mining and Geosciences (NIMG), Jos",
            "Ajaokuta Steel Company Limited",
            "National Iron Ore Mining Company (NIOMCO)",
            "Nigerian Coal Corporation, Enugu",
        ]
    },
    {
        ministry: "Ministry of Petroleum Resources",
        parastatal: [
            "The Nigerian National Petroleum Corporation (NNPC)",
            "Petroleum Training Institute (PTI)",
            "Nigerian Nuclear Regulatory Authority (NNRA)",
            "Petroleum Technology Development Fund (PDTF)",
            "Nigerian Content development and Monitoring Board (NCDMB)",
            "Nigerian Midstream and Downstream Petroleum Regulatory Authority ( NMDPRA)",
            "Nigeria Upstream Petroleum Regulatory Commission (NUPRC)",
            "National Petroleum Investment Management Services (NAPIMS)",
        ]
    },
    {
        ministry: "Ministry of Police Affairs",
        parastatal: [
            "Police Service Commission",
        ]
    },
    {
        ministry: "Ministry of Power",
        parastatal: [
            "Nigerian Electrification Agency (NREA)",
            "National Power Training Institute of Nigeria (NAPTIN)",
            "National Rural Electrification Agency (NREA)",
            "Power Holding Company of Nigeria (PHCN)",
            "Transmission Company of Nigeria",
            "Nigerian Electricity Liabilities Management Ltd./GTE (NELMCO; Nigerian Bulk Electricity Trading"
        ]
    },
    {
        ministry: "Federal Ministry of Science and Technology",
        parastatal: [
            "National Agency for Science and Engineering Infrastructure (NASENI)",
            "Federal Institute for Industrial Research, Oshodi (FIIRO)",
            "Projects development Institute (PRODA)",
            "Sheda Science and Technology Complex",
            "National Space Research and Development Agency (NASRDA)",
            "Nigerian Institute of Science Laboratory Technology (NISLT)",
            "Nigerian Building and Road Research Institute (NBRRI)",
            "Nigerian Institute for Trypanosomiasis Research (NITR)",
            "National Board for Technology Incubation (NBTI)",
            "Nigeria Natural Medicine Developmement Agency (NNMDA)",
            "National Biotechnology Development Agency (NABDA)",
            "Raw Materials Research and Development Council (RMRDC)",
            "Nigerian Institute for Leather Science Technology (NILEST)",
            "National Research Institute for Chemical Technology (NARICT)",
            "National Office for Technology Acquisition and Promotion (NOTAP)",
            "National Centre for Technology Management (NACETEM)",
            "Energy Commission of Nigeria (ECN)",
        ]
    },
    {
        ministry: "Ministry Of Tourism, Culture and National Orientation",
        parastatal: [
            "National Council for Arts and Culture (NCAC)",
            "National Theatre",
            "National Troupe of Nigeria",
            "National Commission for Museum and Monuments",
            "National Gallery of Arts",
            "Nigerian Tourism Development Corporation (NTDC)",
            "Nigerian Institute for Hospitality and Tourism Studies (NIHOTOUR)",
            "National Orientation Agency (NOA)",
            "National Institute for Cultural Orientation (NICO)",
            "Centre for Black and African Arts and Civilisation (CBAAC)",
        ]
    },
    {
        ministry: "Ministry of Industry, Trade and Investment",
        parastatal: [
            "Standards Organisation of Nigeria (SON)",
            "Consumer Protection Council (CPC)",
            "Nigerian Export Promotion Council",
            "Nigerian Investment Promotion Commission",
            "Centre for Automative Design and Development (CADD)",
            "National Automative Council (NAC)",
            "Nigerian Export Processing Zones Authority (NEPZA)",
            "Onne Oil and Gas Free Zone Authority (OGFZA)",
            "Tafawa Balewa Square Management Board and Lagos International Trade Fair Management Board",
            "Corporate Affairs Commission (CAC)",
            "National Sugar Development Council (NSDC)",
            "Nigerian Financial Reporting Council (NFRC)",
            "Industrial Training Fund (ITF)",
            "Small and Medium Enterprises Development Agency of Nigeria (SMEDAN) and National Directorate of Employment (NDE)",
            "Bank of Industry (BOI)",
            "Abuja Commodities and Securities Exchange (ACSE)",
            "Commercial Law Department",
            "Nigerian Investment Promotion Commission (NIPC)",
        ]
    },
    {
        ministry: "Ministry Of Transport",
        parastatal: [
            "Nigerian Ports Authority (NPA)",
            "Nigerian Maritime Administration Safety Agency (NIMASA)",
            "Nigeria Railway Corporation (NRC)",
            "National Inland Waterways Authority (NIWA)",
            "Maritime Academy of Nigeria, Oron",
            "Nigerian Shippers’ Council",
            "Nigerian Institute of Transport Technology (NITT)",
            "Council for the Regulation of Freight Forwarding in Nigeria (CRFFN)",
        ]
    },
    {
        ministry: "Ministry of Water Resources",
        parastatal: [
            "River Basins Development Authorities",
            "Nigeria Hydrological Services Agency (NIHSA)",
            "Nigerian Integrated Water Resources Management Agency (NIWRMA)",
            "Gurara Water Management Authority (GWMA)",
            "National Water Resources Institute",
        ]
    },
    {
        ministry: "Ministry of Women Affairs",
        parastatal: [
            "National Centre for Women Development"
        ]
    },
    {
        ministry: "Ministry of Works",
        parastatal: [
            "Federal Road Maintenance Agency (FERMA)",
            "Office of the Surveyor- General of the Federation",
            "Surveyors’ Council of Nigeria",
            "Council for the Regulation of Engineering in Nigeria",
            "Ministry of Youth Development",
            "Citizens and Leadership Training Centre (C&LTC)",
            "National Youth Service Corps (NYSC)"
        ]
    },
    {
        ministry: "National Sports Commission",
        parastatal: [
            "National Sports Commission",
            "National Institute for Sports (NIS)",
            "Nigeria Football Federation (NFF)/Nigerian Football Association (NFA)",
        ]
    },
    {
        ministry: "Professional Bodies",
        parastatal: [
            "African Institute for Applied Economics (AIAE)",
            "African Finance and Economics Association (AFEA)",
            "Association of General and Private Medical Practitioners of Nigeria (AGPMPN)",
            "Association of National Accountants of Nigeria (ANAN)",
            "Association for Consulting Engineering in Nigeria (ACEN)",
            "Business Education Exams Council (BEEC)",
            "Centre for Law and Development (CLD)",
            "Certified Pension Institute of Nigeria (CPIN)",
            "Chartered Institute of Certified Secretaries and Reporters (CICSR)",
            "Institute of Public Diplomacy and Management (IPDM)",
            "Chartered Institute of Bankers of Nigeria (CIBN)",
            "Chartered Institute of Administration (CIA)",
            "Certified Institute of Warehousing and Materials Management (CISM)",
            "Certified Institute of Cost and Management Accountants of Nigeria (CICMA)",
            "Chartered Institute of Commerce of Nigeria (CICN)",
            "Chartered Institute of Management Accountants (CIMA)",
            "Chartered Institute of Marketing of Nigeria (CIMN)",
            "Chartered Insurance Institute of Nigeria (CIIN)",
            "Chartered Institute of Personnel Management of Nigeria (CIPM)",
            "Chartered Institute of Stockbrokers (CIS)",
            "Chartered Institute of Taxation of Nigeria (CITN)",
            "Chartered Institute of Finance and Control of Nigeria (CIFCN)",
            "Computer Forensics Institute of Nigeria, CFIN",
            "Council of Registered Builders of Nigeria (CORBON)",
            "Council for the Regulation of Engineering in Nigeria (COREN)",
            "CPI Education Training Center (CETC)",
            "Financial Institutions Training Centre (FITC)",
            "Institute of Aviation and Maritime Studies (IAMS)",
            "Institute of Chartered Accountants of Nigeria (ICAN)",
            "Institute of Chartered Secretaries and Administrators Of Nigeria (ICSAN)",
            "Institute of Credit & Risk Management (ICRM)",
            "Institute of Chartered Economists of Nigeria (I.C.E.N)",
            "Institute of Registered Administrative Managers of Nigeria (IRAMN)",
            "Institute of Certified Public Accountants of Nigeria (ICPAN)",
            "Institute of Chartered Economists of Nigeria (ICEN)",
            "Institute of Business Technology Management of Nigeria (IBTM)",
            "Institute of Industrial Security and Safety of Nigeria (IISSN)",
            "Institute of Public Analysts of Nigeria (IPAN)",
            "Institute of Public Management (IPM)",
            "Institute of Fraud Examiners (IFE)",
            "Institute of Strategic Management of Nigeria (ISMN)",
            "Institute of Professional Recruitment Consultants, IPRC Nigeria (IPRC)",
            "Institute of Data Processing Management of Nigeria (IDPMN)",
            "Institute of Cost Management (ICMN)",
            "Institute of Directors Nigeria (IOD)",
            "Institute of Management Consultancy (IMC)",
            "Institute of Treasury Management (ITM)",
            "Manufacturer’s Association of Nigeria (MAN)",
            "Medical and Dental Consultants’ Association of Nigeria (MDCAN)",
            "Medical and Dental Council of Nigeria (MDCN)",
            "Money Market Association of Nigeria (MMAN)",
            "Information Technology Association of Nigeria (ITAN)",
            "National Association of Nigeria Nurses and Midwives (NANNM)",
            "National Institute of Marketing of Nigeria (NIMN)",
            "Nigeria Bar Association (NBA)",
            "Nigerian Institute of Advanced Legal Studies",
            "Nigerian Institute of Architects (NIA)",
            "Nigerian Institution of Estate Surveyors and Valuers (NIESV)",
            "Nigerian Institute of Food Science and Technology (NIFST)",
            "Nigerian Institute of Management (NIM)",
            "Nigerian Institute of Public Relations (NIPR)",
            "Nigeria Institute of Quantity Surveyors (NIQS)",
            "Nigerian Institute of Science Laboratory Technology (NISLT)",
            "Nigerian Institute of Town Planners (NITP)",
            "Nigeria Institute of Social and Economic Research (NISER)",
            "Nigeria Institute of Professional Secretaries (NIPS)",
            "National Teachers’ Institute (NTI)",
            "Nigeria Insurers Association (NIA)",
            "Nigeria Institute of Building (NIOB)",
            "Nigeria Institute of Safety Professionals (NISP)",
            "Nigeria Institute of Industrial Management (NIIM)",
            "Nigeria Institute of Civil Engineers (NICE)",
            "Nigeria Computer Society (NCS)",
            "Nigerian Medical Association (NMA)",
            "Outdoor Advertising Association of Nigeria (OAAN)",
            "Pharmacists Council of Nigeria (PCN)",
            "Pharmaceutical Society of Nigeria (PSN)",
            "Pipeline Professionals’ Association of Nigeria (PLAN)",
            "Science Teachers Association of Nigeria (STAN)",
            "Strategic Management Centre (SMC)",
            "Society of Construction Industry Arbitrators of Nigeria (SCIAN)",
            "The Nigeria Institute of Safety Professionals (NISP)",
            "The Institute of Certified Geographers of Nigeria (ICGN)",
            "The Institute of Business Development (IBD)",
            "The Institute of Benefits and Trusts Management (IBTM)",
            "The Certified Pension Institute of Nigeria (CPIN)",
            "The Institute of Credit Administration of Nigeria (ICA)",
            "The Institute of Company and Commercial Accounts of Nigeria (ICCAN)",
            "The Nigerian Society of Engineers (NSE)",
            "The Institute of Registered Administrative Managers of Nigeria (IRAMN)",
            "The Chartered Institute of Logistics and Transport (CILT)",
            "The Chartered Institute of Purchasing and Supply Management of Nigeria (CIPSMN)",
            "The Institute of Business and Economic Analysts, Nigeria (IBEAN)",
            "West African College of Physicians",
            "West African Institute of Financial and Economic Management (WAIFEM)",
            "Institute of Human Resources and Strategic Management (IHRSM)",
        ]
    },
    {
        ministry: "Others/Constitutional bodies",
        parastatal: [
            "Code of Conduct Bureau (CCB)",
            "Council of State (COS)",
            "Federal Character Commission",
            "Federal Civil Service Commission (FCSC)",
            "Federal Judicial Service Commission",
            "Independent National Electoral Commission",
            "National Defence Council",
            "National Economic Council",
            "National Judicial Council",
            "National Population Commission (NPC)",
            "National Security Council (NSC)",
            "Nigerian Police Council (NPC)",
            "Police Service Commission (PSC)",
            "Revenue Mobilisation, Allocation and Fiscal Commission",
            "Fiscal Responsibility Commission (FRC)",
            "Bureau of Public Procurement (BPP)",
            "Central Bank of Nigeria (CBN)",
            "Code of Conduct Tribunal",
            "Economic and Financial Crimes Commission (EFCC)",
            "Independent Corrupt Practices and other Related Offences Commission (ICPC)",
            "Infrastructure Concessionary and Regulatory Commission (ICRC)",
            "Nigerian Atomic Energy (NAEC)",
            "National Pensions Commission (PENCOM)",
            "Public Complaints Commission (PCC)",
            "National Salaries, Incomes and Wages Commission (NSIWC)",
            "mission (NSIWC)",
            "National Human Rights Commission",
            "Proposed Ministry of Special Duties"
        ]
    },
]





















export let allParastatalData = [
    "National Boundary Commission (NBC)",
    "Border Communities Development Agencies (BCDA)",
    "National Institute for Policy and Strategic Studies (NIPPS)",
    "National Emergency Management (NEMA)",
    "National Commission for Refugees (NCR)",
    "Debt Management Office (DMO)",
    "Niger Delta Power Holding Company (NDPHC)",
    "The National Planning Commission",
    "National Bureau of Statistics (NBS)",
    "Centre for Management Development (CMD)",
    "Nigerian Institute of Social and Economic Research (NISER)",



    "National Poverty Eradication Programme (NAPEP)",
    "Nigerian National Merit Award (NNMA)",
    "Federal Road Safety Commission (FRSC)",
    "Niger Delta Development Commission (NDDC)",
    "New Partnership for Africa’s Development (NEPAD)",
    "Utilities Charges Commission (UCC)",
    "National Agency for the Control of HIV/AIDS (NACA)",
    "National Hajj Commission of Nigeria (NAHCON)",
    "Nigerian Christian Pilgrims Commission (NCPC)",
    "National Lottery Regulatory Commission (NLRC)",
    "National Lottery Trust Fund (NLTF)",
    "Service Compact with All Nigerians (SERVICOM)",
    "Nigerian Extractive Industries Transparency Initiative (NEITI)",
    "National Economic intelligence Committee (NEIC)",
    "Code of Conduct Bureau/Code of Conduct Tribunal",



    "Administrative Staff College of Nigeria (ASCON)",
    "Public Service Institute of Nigeria (PSIN)",
    "Bureau of Public Service Reforms (BPSR)",
    "Federal Government Staff Housing Loans Board (FGSHLB)",
    "Agricultural Research Council of Nigeria (ARCN)",
    "Agricultural Research and Rural Management Institute (ARMTI)",
    "National Centre for Agricultural Mechanization (NCAM)",
    "Bank of Agriculture (BOA)",
    "National Livestock Development Project (NLDP), Kaduna",
    "National Agricultural Insurance Corporation (NAIC)",
    "National Food Reserve Agency",
    "Nigeria Agricultural Quarantine Service",
    "National Agriculture Seeds Council (NASC)",
    "Veterinary Council of Nigeria",



    "Nigerian Civil Aviation Authority (NCAA)",
    "Nigerian Airspace Management Agency (NAMA)",
    "Accident Investigation Bureau (AIB)",
    "Nigerian Meteorological Agency (NIMET)",
    "Federal Airports Authority of Nigeria (FAAN)",
    "Nigeria College of Aviation (NCAT)",



    "Nigerian Communications Commission (NCC)",
    "National Information Technology Development Agency (NITDA)",
    "Universal Service Provision Fund (USPF)",
    "Nigerian Postal Agency (NIPOST)",
    // "Nigerian Telecommunications Limited (NITEL)",
    "Galaxy Backbone Limited (GBL)",
    "Nigerian Communications Satellite (NigComSat) Limited",



    "Defence Industries Corporation of Nigeria (DICON)",
    "National Defence College (NDC)",
    "Nigerian Defence Academy (NDA)",
    "Armed Forces Command and Staff College (AFCSC)",
    "Military Pensions Board (MPB)",
    "Defence Intelligence Agency (DIA)",
    "Nigerian Armed Forces Resettlement Centre (NAFRC)",
    "Presidential Committee on Barracks Rehabilitation (PCBR)",
    "Defence Health Maintenance Company Limited",



    "West African Examination Council (WAEC)",
    "National examination Council (NECO)",
    "Joint Admission and Matriculation Board (JAMB)",
    "National Universities Commission (NUC)",
    "National Board of Technical Education (NBTE)",
    "National Commission for Colleges of Education (NCCE)",
    "Tertiary Education Trust Fund (TETFund)",
    "National Open University of Nigeria (NOUN)",
    "National Library of Nigeria",
    "Universal Basic Education Commission (UBEC)",
    "National Commission for Nomadic Education",
    "National Commission for Mass Literacy, Adult and Non-Formal Education",
    "National Teachers’ Institute (NTI)",
    "Nigerian Institute for Educational Planners and Administrators (NIEPA)",
    "Nigerian Educational Research and Development Council (NERDC)",
    "The Librarians’ Registration Council of Nigeria (LRCN)",
    "Teachers registration Council of Nigeria (TRCN)",
    "Computer Professionals Council of Nigeria (CPRCN)",
    "The National Mathematical Centre (NMC)",
    "French Language Village",
    "Arabic Language Village",
    "Nigerian French Language village (NFLV)",
    "Nigerian Arabic Language Village (NALV)",
    "National Oil Spill Detection and Response Agency (NOSDRA)",
    "National Environmental Standards and Regulations Enforcement Agency (NESREA)",
    "National Park Headquarters",
    "Forestry Research Institute of Nigeria, Ibadan",
    "Environmental Health Registration Council of Nigeria (EHRCN)",



    "Federal Capital Territory Authority",



    "Federal Inland Revenue (FIRS)",
    "Office of the Accountant General of the Federation",
    "Ministry of finance Incorporated (MOFI)",
    "Nigeria Customs Service (NCS)",
    "Investment and Securities Tribunal",
    "National Insurance Commission (NAICOM)",
    "National Economic Recovery Fund (NERFUND)",
    "Nigerian Import and Export Bank (NEXIM)",
    "Security and Exchange Commission (SEC)",
    "Nigerian Deposit Insurance Corporation (NDIC)",



    "The Nigerian Institute of Internal Affairs (NIIA)",
    "Institute for Peace and Conflict Resolution",
    "The Directorate of Technical Cooperation in Africa (DTCA)",
    "The Directorate of Technical Aid Corps (DTAC)",



    "Teaching Hospitals",
    "Federal Medical Centres",
    "Specialty Hospitals",
    "Medical Research Centres",
    "Training Institutes",
    "Regulatory Bodies such as Nursing and Midwifery Council; Medical and Dental Council of Nigeria; Medical Laboratory Science Council of Nigeria; Radiographers Registration Board; Dental Technicians Registration Board; Optometrists and Dispensing Opticians Registration Board; Medical Rehabilitation Therapy Board; Dental Therapy Registration Board; Pharmacists Council of Nigeria; Institute of Chartered Chemists; Institute of Public Analysts; and Health Records Registration Board.",
    "NAFDAC",
    "Other Agencies under the Federal Ministry of Health",



    "Federal Radio Corporation (FRCN)",
    "Voice of Nigeria (VON)",
    "Nigerian Television Authority (NTA)",
    "News Agency of Nigeria (NAN)",
    "National Broadcasting Commission (NBC)",
    "Nigeria Film Corporation (NFC)",
    "National Film Video Censor Board (NFVCB)",
    "Advertising Practitioners Council of Nigeria (APCON)",
    "Nigerian Press Council (NPC)",



    "Nigerian Immigration Service (NIS)",
    "Nigerian Prisons Service (NPS)",
    "Nigerian Security and Council Defence Corps (NSCDC)",
    "Federal Fire Service",
    "Civil Defence, Immigration and Prisons Services Board (CDIPB)",
    "Customs, Immigration and Prisons Pension Office (CIPPO)",
    "National Identity Management Commission (NIMC)",



    "Council of Legal Education, Nigerian Law School (CLE & NLS)",
    "Legal Aid Council (LAC)",
    "Nigerian Copyright Commission",
    "Nigerian Law Reform Commission (NLRC)",
    "National Agency for Prohibition of Trafficking in Persons and other related Matters (NAPTIP)",
    "Nigerian Institute of Advanced Legal Studies (NIALS)",
    "National Law Drug Enforcement Agency (NDLEA)",
    "Regional Centre for International Commercial Arbitration",



    "National Directorate of Employment (NDE)",
    "National Productivity Centre (NPC)",
    "Michael Imoudu National Institute of Labour Studies (MINILS)",
    "Industrial Arbitration Panel (IAP)",
    "Nigeria Social Insurance Trust Fund (NSITF)",



    "Federal Housing Authority (FHA)",
    "Federal Mortgage Bank of Nigeria (FMBN)",



    "Architects Registration Council (ARC).",
    "Council for Registered Engineers of Nigeria (COREN)",
    "Estate Surveyors’ Registration Board (ESRB)",
    "Town Planners Council (TPC)",
    "Nigerian Builders Council (NBC)",
    "Quantity Surveyors’ Registration Board of Nigeria (QSRB)",
    "Nigerian Bar Association (NBA)",



    "Council of Nigerian Mining Engineers and Geoscientists (COMEG)",
    "Nigerian Mining Cadastre Office (NMCO)",
    "National Steel Raw Materials Exploration Agency (NSRMEA)",
    "Nigerian Geological Survey Agency (NGSA)",
    "National Metallurgical Development Centre (NMDC), Jos",
    "National Metallurgical Training Institute, Onitsha",
    "Nigerian Institute of Mining and Geosciences (NIMG), Jos",
    "Ajaokuta Steel Company Limited",
    "National Iron Ore Mining Company (NIOMCO)",
    "Nigerian Coal Corporation, Enugu",



    "The Nigerian National Petroleum Corporation (NNPC)",
    "Petroleum Training Institute (PTI)",
    "Nigerian Nuclear Regulatory Authority (NNRA)",
    "Petroleum Technology Development Fund (PDTF)",
    "Nigerian Content development and Monitoring Board (NCDMB)",
    "Nigerian Midstream and Downstream Petroleum Regulatory Authority ( NMDPRA)",
    "Nigeria Upstream Petroleum Regulatory Commission (NUPRC)",
    "National Petroleum Investment Management Services (NAPIMS)",



    "Police Service Commission",



    "Nigerian Electrification Agency (NREA)",
    "National Power Training Institute of Nigeria (NAPTIN)",
    "National Rural Electrification Agency (NREA)",
    "Power Holding Company of Nigeria (PHCN)",
    "Transmission Company of Nigeria",
    "Nigerian Electricity Liabilities Management Ltd./GTE (NELMCO; Nigerian Bulk Electricity Trading",



    "National Agency for Science and Engineering Infrastructure (NASENI)",
    "Federal Institute for Industrial Research, Oshodi (FIIRO)",
    "Projects development Institute (PRODA)",
    "Sheda Science and Technology Complex",
    "National Space Research and Development Agency (NASRDA)",
    "Nigerian Institute of Science Laboratory Technology (NISLT)",
    "Nigerian Building and Road Research Institute (NBRRI)",
    "Nigerian Institute for Trypanosomiasis Research (NITR)",
    "National Board for Technology Incubation (NBTI)",
    "Nigeria Natural Medicine Developmement Agency (NNMDA)",
    "National Biotechnology Development Agency (NABDA)",
    "Raw Materials Research and Development Council (RMRDC)",
    "Nigerian Institute for Leather Science Technology (NILEST)",
    "National Research Institute for Chemical Technology (NARICT)",
    "National Office for Technology Acquisition and Promotion (NOTAP)",
    "National Centre for Technology Management (NACETEM)",
    "Energy Commission of Nigeria (ECN)",



    "National Council for Arts and Culture (NCAC)",
    "National Theatre",
    "National Troupe of Nigeria",
    "National Commission for Museum and Monuments",
    "National Gallery of Arts",
    "Nigerian Tourism Development Corporation (NTDC)",
    "Nigerian Institute for Hospitality and Tourism Studies (NIHOTOUR)",
    "National Orientation Agency (NOA)",
    "National Institute for Cultural Orientation (NICO)",
    "Centre for Black and African Arts and Civilisation (CBAAC)",



    "Standards Organisation of Nigeria (SON)",
    "Consumer Protection Council (CPC)",
    "Nigerian Export Promotion Council",
    "Nigerian Investment Promotion Commission",
    "Centre for Automative Design and Development (CADD)",
    "National Automative Council (NAC)",
    "Nigerian Export Processing Zones Authority (NEPZA)",
    "Onne Oil and Gas Free Zone Authority (OGFZA)",
    "Tafawa Balewa Square Management Board and Lagos International Trade Fair Management Board",
    "Corporate Affairs Commission (CAC)",
    "National Sugar Development Council (NSDC)",
    "Nigerian Financial Reporting Council (NFRC)",
    "Industrial Training Fund (ITF)",
    "Small and Medium Enterprises Development Agency of Nigeria (SMEDAN) and National Directorate of Employment (NDE)",
    "Bank of Industry (BOI)",
    "Abuja Commodities and Securities Exchange (ACSE)",
    "Commercial Law Department",
    "Nigerian Investment Promotion Commission (NIPC)",



    "Nigerian Ports Authority (NPA)",
    "Nigerian Maritime Administration Safety Agency (NIMASA)",
    "Nigeria Railway Corporation (NRC)",
    "National Inland Waterways Authority (NIWA)",
    "Maritime Academy of Nigeria, Oron",
    "Nigerian Shippers’ Council",
    "Nigerian Institute of Transport Technology (NITT)",
    "Council for the Regulation of Freight Forwarding in Nigeria (CRFFN)",



    "River Basins Development Authorities",
    "Nigeria Hydrological Services Agency (NIHSA)",
    "Nigerian Integrated Water Resources Management Agency (NIWRMA)",
    "Gurara Water Management Authority (GWMA)",
    "National Water Resources Institute",



    "National Centre for Women Development",



    "Federal Road Maintenance Agency (FERMA)",
    "Office of the Surveyor- General of the Federation",
    "Surveyors’ Council of Nigeria",
    "Council for the Regulation of Engineering in Nigeria",
    "Ministry of Youth Development",
    "Citizens and Leadership Training Centre (C&LTC)",
    "National Youth Service Corps (NYSC)",



    "National Sports Commission",
    "National Institute for Sports (NIS)",
    "Nigeria Football Federation (NFF)/Nigerian Football Association (NFA)",



    "African Institute for Applied Economics (AIAE)",
    "African Finance and Economics Association (AFEA)",
    "Association of General and Private Medical Practitioners of Nigeria (AGPMPN)",
    "Association of National Accountants of Nigeria (ANAN)",
    "Association for Consulting Engineering in Nigeria (ACEN)",
    "Business Education Exams Council (BEEC)",
    "Centre for Law and Development (CLD)",
    "Certified Pension Institute of Nigeria (CPIN)",
    "Chartered Institute of Certified Secretaries and Reporters (CICSR)",
    "Institute of Public Diplomacy and Management (IPDM)",
    "Chartered Institute of Bankers of Nigeria (CIBN)",
    "Chartered Institute of Administration (CIA)",
    "Certified Institute of Warehousing and Materials Management (CISM)",
    "Certified Institute of Cost and Management Accountants of Nigeria (CICMA)",
    "Chartered Institute of Commerce of Nigeria (CICN)",
    "Chartered Institute of Management Accountants (CIMA)",
    "Chartered Institute of Marketing of Nigeria (CIMN)",
    "Chartered Insurance Institute of Nigeria (CIIN)",
    "Chartered Institute of Personnel Management of Nigeria (CIPM)",
    "Chartered Institute of Stockbrokers (CIS)",
    "Chartered Institute of Taxation of Nigeria (CITN)",
    "Chartered Institute of Finance and Control of Nigeria (CIFCN)",
    "Computer Forensics Institute of Nigeria, CFIN",
    "Council of Registered Builders of Nigeria (CORBON)",
    "Council for the Regulation of Engineering in Nigeria (COREN)",
    "CPI Education Training Center (CETC)",
    "Financial Institutions Training Centre (FITC)",
    "Institute of Aviation and Maritime Studies (IAMS)",
    "Institute of Chartered Accountants of Nigeria (ICAN)",
    "Institute of Chartered Secretaries and Administrators Of Nigeria (ICSAN)",
    "Institute of Credit & Risk Management (ICRM)",
    "Institute of Chartered Economists of Nigeria (I.C.E.N)",
    "Institute of Registered Administrative Managers of Nigeria (IRAMN)",
    "Institute of Certified Public Accountants of Nigeria (ICPAN)",
    "Institute of Chartered Economists of Nigeria (ICEN)",
    "Institute of Business Technology Management of Nigeria (IBTM)",
    "Institute of Industrial Security and Safety of Nigeria (IISSN)",
    "Institute of Public Analysts of Nigeria (IPAN)",
    "Institute of Public Management (IPM)",
    "Institute of Fraud Examiners (IFE)",
    "Institute of Strategic Management of Nigeria (ISMN)",
    "Institute of Professional Recruitment Consultants, IPRC Nigeria (IPRC)",
    "Institute of Data Processing Management of Nigeria (IDPMN)",
    "Institute of Cost Management (ICMN)",
    "Institute of Directors Nigeria (IOD)",
    "Institute of Management Consultancy (IMC)",
    "Institute of Treasury Management (ITM)",
    "Manufacturer’s Association of Nigeria (MAN)",
    "Medical and Dental Consultants’ Association of Nigeria (MDCAN)",
    "Medical and Dental Council of Nigeria (MDCN)",
    "Money Market Association of Nigeria (MMAN)",
    "Information Technology Association of Nigeria (ITAN)",
    "National Association of Nigeria Nurses and Midwives (NANNM)",
    "National Institute of Marketing of Nigeria (NIMN)",
    "Nigeria Bar Association (NBA)",
    "Nigerian Institute of Advanced Legal Studies",
    "Nigerian Institute of Architects (NIA)",
    "Nigerian Institution of Estate Surveyors and Valuers (NIESV)",
    "Nigerian Institute of Food Science and Technology (NIFST)",
    "Nigerian Institute of Management (NIM)",
    "Nigerian Institute of Public Relations (NIPR)",
    "Nigeria Institute of Quantity Surveyors (NIQS)",
    "Nigerian Institute of Science Laboratory Technology (NISLT)",
    "Nigerian Institute of Town Planners (NITP)",
    "Nigeria Institute of Social and Economic Research (NISER)",
    "Nigeria Institute of Professional Secretaries (NIPS)",
    "National Teachers’ Institute (NTI)",
    "Nigeria Insurers Association (NIA)",
    "Nigeria Institute of Building (NIOB)",
    "Nigeria Institute of Safety Professionals (NISP)",
    "Nigeria Institute of Industrial Management (NIIM)",
    "Nigeria Institute of Civil Engineers (NICE)",
    "Nigeria Computer Society (NCS)",
    "Nigerian Medical Association (NMA)",
    "Outdoor Advertising Association of Nigeria (OAAN)",
    "Pharmacists Council of Nigeria (PCN)",
    "Pharmaceutical Society of Nigeria (PSN)",
    "Pipeline Professionals’ Association of Nigeria (PLAN)",
    "Science Teachers Association of Nigeria (STAN)",
    "Strategic Management Centre (SMC)",
    "Society of Construction Industry Arbitrators of Nigeria (SCIAN)",
    "The Nigeria Institute of Safety Professionals (NISP)",
    "The Institute of Certified Geographers of Nigeria (ICGN)",
    "The Institute of Business Development (IBD)",
    "The Institute of Benefits and Trusts Management (IBTM)",
    "The Certified Pension Institute of Nigeria (CPIN)",
    "The Institute of Credit Administration of Nigeria (ICA)",
    "The Institute of Company and Commercial Accounts of Nigeria (ICCAN)",
    "The Nigerian Society of Engineers (NSE)",
    "The Institute of Registered Administrative Managers of Nigeria (IRAMN)",
    "The Chartered Institute of Logistics and Transport (CILT)",
    "The Chartered Institute of Purchasing and Supply Management of Nigeria (CIPSMN)",
    "The Institute of Business and Economic Analysts, Nigeria (IBEAN)",
    "West African College of Physicians",
    "West African Institute of Financial and Economic Management (WAIFEM)",
    "Institute of Human Resources and Strategic Management (IHRSM)",



    "Code of Conduct Bureau (CCB)",
    "Council of State (COS)",
    "Federal Character Commission",
    "Federal Civil Service Commission (FCSC)",
    "Federal Judicial Service Commission",
    "Independent National Electoral Commission",
    "National Defence Council",
    "National Economic Council",
    "National Judicial Council",
    "National Population Commission (NPC)",
    "National Security Council (NSC)",
    "Nigerian Police Council (NPC)",
    "Police Service Commission (PSC)",
    "Revenue Mobilisation, Allocation and Fiscal Commission",
    "Fiscal Responsibility Commission (FRC)",
    "Bureau of Public Procurement (BPP)",
    "Central Bank of Nigeria (CBN)",
    "Code of Conduct Tribunal",
    "Economic and Financial Crimes Commission (EFCC)",
    "Independent Corrupt Practices and other Related Offences Commission (ICPC)",
    "Infrastructure Concessionary and Regulatory Commission (ICRC)",
    "Nigerian Atomic Energy (NAEC)",
    "National Pensions Commission (PENCOM)",
    "Public Complaints Commission (PCC)",
    "National Salaries, Incomes and Wages Commission (NSIWC)",
    "mission (NSIWC)",
    "National Human Rights Commission",
    "Proposed Ministry of Special Duties"
]